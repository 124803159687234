import styled from 'styled-components';
import { ReactComponent as ErrorIconSvg } from '../../../../../assets/images/icons/alert-circle.svg';
import { ReactComponent as WarningIconSvg } from '../../../../../assets/images/icons/alert-triangle.svg';
import Bold from '../../../../../components/Bold';

export const ErrorIcon = styled(ErrorIconSvg)`
  color: ${({ theme }) => theme.colors.red600};
  height: 1.125rem;
  width: 1.125rem;
`;

export const WarningIcon = styled(WarningIconSvg)`
  color: ${({ theme }) => theme.colors.yellow700};
  height: 1.125rem;
  width: 1.125rem;
`;

export const TitleText = styled(Bold)<{
  $variant: 'error' | 'warning' | 'normal';
}>`
  font-variant: tabular-nums;
  color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'error':
        return theme.colors.red600;
      case 'warning':
        return theme.colors.yellow700;
      default:
        return theme.colors.black;
    }
  }};
`;

export const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: 1.125rem 1fr;
  grid-gap: 0.6rem;
  align-items: center;
`;
