import React, { FC, useCallback } from 'react';
import DataView from 'components/DataView';
import DesktopTable from 'components/DesktopTable';
import Row from 'components/MobileTable/Row';
import MobileTable from 'components/MobileTable';
import { ScheduledCampaign } from 'model/scheduledCampaign';
import { RowLink } from 'components/DesktopTable/Rows/styles';
import { Props } from './types';
import Search from './table/filter';
import Exporter from './table/exporter';
import Empty from './table/empty';
import NotFound from './table/notFound';
import { useConnect } from './connect';
import ScheduledCampaignDetail from './ScheduledCampaignDetail';
import {
  isCampaignInterruptedBecauseOfQuota,
  isErrorStatus,
} from '../../../../utils/scheduledCampaigns';

const getVariant = (
  scheduledCampaign: ScheduledCampaign,
): 'error' | 'warning' | 'normal' => {
  if (isCampaignInterruptedBecauseOfQuota(scheduledCampaign)) {
    return 'warning';
  }
  if (isErrorStatus(scheduledCampaign.status)) {
    return 'error';
  }
  return 'normal';
};

const ScheduledMailMerges: FC<Props> = ({
  scheduledCampaigns,
  isMobile,
  isLoading,
}) => {
  const { columns, scheduledTestingVar, url } = useConnect();
  const rowLink = useCallback(
    (props: { item: ScheduledCampaign }) => (
      <RowLink to={`${url}/${props.item.id}`} tabIndex={-1} />
    ),
    [url],
  );
  return (
    <>
      <DataView>
        {isMobile ? (
          <MobileTable
            data={scheduledCampaigns}
            columns={columns}
            loading={isLoading}
            row={(item) => (
              <Row
                variant={getVariant(item)}
                scheduled
                url={`${url}/${item.id}`}
                key={item.id}
                name={item.title}
                sent={item.numberOfEmailsToSend}
                date={item.mergeDate}
                time={item.mergeDateTime}
              />
            )}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
            disableFilter
          />
        ) : (
          <DesktopTable
            data={scheduledCampaigns}
            loading={isLoading}
            columns={columns}
            rowLink={rowLink}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
            disableFilter
          />
        )}
        {scheduledTestingVar ? (
          <span data-test-id={scheduledTestingVar} />
        ) : null}
      </DataView>
      <ScheduledCampaignDetail />
    </>
  );
};

export default ScheduledMailMerges;
