import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import {
  ConfirmModal,
  Container,
  ConfirmText,
  ConfirmButton,
  RemoveIcon,
  AdminBadge,
  InstallationPendingBadge,
  AdminContainer,
} from './styles';
import { useConnect } from './connect';
import { Props } from './types';
import { InfoIcon, Tooltip } from '../styles';

export function UserSeatActions({
  subscriptionId,
  id,
  isAdmin,
  onDelete,
  isYammUser,
  displayAdminTooltip,
}: Props) {
  const { showConfirm, setShowConfirm, onRemove } = useConnect({
    subscriptionId,
    onDelete,
    id,
  });

  return (
    <Container>
      {isAdmin && (
        <AdminContainer>
          {displayAdminTooltip && (
            <Tooltip title="Contact us if you want to transfer your license within your domain. Click to open a ticket.">
              <a
                style={{ textDecoration: 'none' }}
                href="https://support.yet-another-mail-merge.com/hc/en-us/requests/new"
                target="_blank"
                rel="noreferrer noopener"
              >
                <InfoIcon />
              </a>
            </Tooltip>
          )}
          <AdminBadge>Admin</AdminBadge>
        </AdminContainer>
      )}
      {!isYammUser && (
        <Tooltip
          title={
            <>
              <span>This user has yet to install YAMM.</span>
              <br />
              <i>Click to see instructions</i>
            </>
          }
        >
          <a
            style={{ textDecoration: 'none' }}
            href="https://support.yet-another-mail-merge.com/hc/en-us/articles/211750825-Install-Yet-Another-Mail-Merge-on-your-individual-account"
            target="_blank"
            rel="noreferrer noopener"
          >
            <InstallationPendingBadge>
              YAMM installation pending
            </InstallationPendingBadge>
          </a>
        </Tooltip>
      )}
      <RemoveIcon onClick={() => setShowConfirm(true)} />
      <ConfirmModal show={showConfirm}>
        <ConfirmText>Are you sure?</ConfirmText>
        <ConfirmButton type="button" onClick={onRemove} variant="CONFIRM">
          <CheckIcon />
          <span>Yes</span>
        </ConfirmButton>
        <ConfirmButton
          type="button"
          onClick={() => setShowConfirm(false)}
          variant="ALERT"
        >
          <CloseIcon />
          <span>No</span>
        </ConfirmButton>
      </ConfirmModal>
    </Container>
  );
}
