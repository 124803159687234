import React, { FC } from 'react';

import { ReactComponent as ExternalLinkIcon } from 'assets/images/icons/external-link.svg';

import Tabs, { Tab } from 'components/Tabs';
import { Route, Switch } from 'react-router-dom';
import { QueryStatus } from 'react-query';
import testingVar from 'utils/testingVar';
import {
  Modal,
  Column,
  Content,
  IconWrapper,
  Info,
  Label,
  Link,
  LinkIcon,
  TitlePlaceholder,
  SubtitlePlaceholder,
  Placeholder,
  SmallPlaceholder,
  PollSupport,
  Help,
  NoSubject,
} from './styles';

import { useConnect } from './connect';
import { Props } from './types';
import MailResults from './MailResults';
import PollResults from './PollResults';

const CampaignDetail: FC<Props> = () => {
  const {
    handle,
    url,
    isOpen,
    mailMerge,
    status,
    lastEmailSent,
    poll,
  } = useConnect();

  return (
    <Modal
      open={isOpen}
      title={mailMerge?.title || <TitlePlaceholder />}
      subtitle={
        status === QueryStatus.Loading ? (
          <SubtitlePlaceholder />
        ) : (
          mailMerge?.emailSubject ?? (
            <NoSubject>
              Subject is unavailable because this mail merge has been sent by
              someone else
            </NoSubject>
          )
        )
      }
      onClose={handle.close}
    >
      <Content data-test-id={testingVar('campaign-details')}>
        <Info>
          <Column>
            <Label>Emails sent</Label>
            {mailMerge?.trackingInfos.sent ? (
              <span>{mailMerge?.trackingInfos.sent}</span>
            ) : (
              <SmallPlaceholder />
            )}
          </Column>
          <Column>
            <Label>Last email sent</Label>
            {lastEmailSent ? (
              <span>{lastEmailSent}</span>
            ) : (
              <SmallPlaceholder />
            )}
          </Column>
        </Info>
        <Tabs size="small">
          <Tab exact to={`${url}`}>
            Mail results
          </Tab>
          {poll ? (
            <Tab
              data-test-id={testingVar('view-poll-results')}
              exact
              to={`${url}/poll`}
            >
              Poll results
            </Tab>
          ) : (
            <PollSupport
              data-test-id={testingVar('no-poll-results')}
              title="Read about YAMM polls and their set up"
              href="https://support.yet-another-mail-merge.com/hc/en-us/articles/115002407565"
              target="_blank"
              rel="noopener noreferrer"
            >
              Poll Results
              <Help />
            </PollSupport>
          )}
        </Tabs>
        <Switch>
          <Route exact path={`${url}`}>
            <MailResults campaign={mailMerge} />
          </Route>
          <Route exact path={`${url}/poll`}>
            {poll ? <PollResults poll={poll} /> : <Placeholder />}
          </Route>
        </Switch>
      </Content>
      <Link
        href={mailMerge?.spreadsheetLink || '#'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconWrapper>
          <ExternalLinkIcon />
        </IconWrapper>
        Open in Google Sheets
        <LinkIcon />
      </Link>
    </Modal>
  );
};

export default CampaignDetail;
