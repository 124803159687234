import React from 'react';
import MessageCard from 'components/MessageCard';

import StaticLayout from 'containers/Common/StaticLayout';
import testingVar from 'utils/testingVar';

const Success = () => {
  return (
    <StaticLayout data-test-id={testingVar('success')}>
      <MessageCard
        variant="success"
        title="Thanks, your response has been recorded"
        subtitle={
          <>
            Your assistant for greater and faster feedback, powered by Yet
            Another Mail Merge
          </>
        }
      />
    </StaticLayout>
  );
};

export default Success;
