import React, { FC, memo } from 'react';
import { LocationDescriptorObject } from 'history';
import { Link, useLocation } from 'react-router-dom';
import { Props } from './types';

const ModalLink: FC<Props> = ({ children, to, ...props }) => {
  const location = useLocation();
  let modalTo: Props['to'] = {
    state: {
      background: location,
    },
  };

  if (typeof to === 'string') {
    modalTo.pathname = to;
  } else if (typeof to === 'object') {
    modalTo.pathname = to.pathname;
  } else {
    const calculatedTo = to(location) as LocationDescriptorObject;
    modalTo = { ...calculatedTo, ...modalTo };
  }

  return (
    <Link {...props} to={modalTo}>
      {children}
    </Link>
  );
};

export default memo(ModalLink);
