import { getBillingInfo } from 'data/requests/billingInfo';
import { normalizeBillingInfo } from 'model/billingInfo';
import { QueryConfig, useQuery } from 'react-query';

export const useBillingInfo = (
  {
    userId,
  }: {
    userId?: string;
  },
  options: QueryConfig<any>,
) => {
  const { data, ...rest } = useQuery(['billing-info', userId], getBillingInfo, {
    enabled: !!userId,
    retry: false,
    ...options,
  });
  const billingInfo = data && normalizeBillingInfo(data);
  return { billingInfo, ...rest };
};
