import { client } from 'data/client';
import { Input } from 'model/domain';

type GetCampaignResponse = Input;

export async function getDomain(
  key: string,
  userId: string,
  includeStats: boolean,
) {
  const { data } = await client.get<GetCampaignResponse>(
    `/users/${userId}/domain`,
    {
      params: {
        includeStats,
      },
    },
  );
  return data;
}
