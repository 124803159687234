import { useQuery } from 'react-query';
import { normalizeCampaign } from 'model/campaign';
import { getMailMerge } from 'data/requests/mailMerge';

export const useMailMerge = (userId?: string, campaignId?: string) => {
  const { data, status } = useQuery(
    ['mail-merge', userId, campaignId],
    getMailMerge,
    {
      enabled: !!userId && !!campaignId,
      refetchInterval: 1000 * 30,
    },
  );
  const mailMerge = data && normalizeCampaign(data);
  return { mailMerge, status };
};
