import styled, { css } from 'styled-components';
import { ReactComponent as DefaultCloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as DefaultErrorIcon } from 'assets/images/icons/error.svg';
import { ReactComponent as DefaultSuccessIcon } from 'assets/images/icons/check.svg';
import { ReactComponent as DefaultWarningIcon } from 'assets/images/icons/alert-triangle.svg';

import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';
import { IconContainerProps } from './types';

const IconCommonStyles = css`
  height: 1rem;
  width: 1rem;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  border-radius: 0.3125rem;
  color: ${({ theme }) => theme.colors.gray3};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  width: 100%;

  ${from.mobile} {
    flex-direction: row;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  display: inline-flex;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;

  ${from.mobile} {
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  color: inherit;
  height: 1rem;
  width: 1rem;

  ${from.mobile} {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${from.mobile} {
    flex-direction: row;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  margin-bottom: 0.5rem;
  width: 1.5rem;

  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'ERROR':
        return css`
          background-color: ${theme.colors.redLight};
        `;
      case 'SUCCESS':
        return css`
          background-color: ${theme.colors.green50};
        `;
      case 'WARNING':
        return css`
          background-color: ${theme.colors.yellow50};
        `;
      default:
        return null;
    }
  }}

  ${from.mobile} {
    height: 2.5rem;
    margin-bottom: 0;
    margin-right: 1.5rem;
    width: 2.5rem;
  }
`;

export const ErrorIcon = styled(DefaultErrorIcon)`
  ${IconCommonStyles}
`;

export const SuccessIcon = styled(DefaultSuccessIcon)`
  color: ${({ theme }) => theme.colors.green700};
  ${IconCommonStyles}
`;

export const WarningIcon = styled(DefaultWarningIcon)`
  ${IconCommonStyles}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin-bottom: 0.25rem;
  text-align: center;

  ${from.mobile} {
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: left;
  }
`;

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.875rem;
  line-height: 1.375rem;
  text-align: center;

  ${from.mobile} {
    text-align: left;
  }
`;
