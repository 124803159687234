import { useState } from 'react';
import { ConnectProps } from './types';

export const useConnect = ({ subscriptionId, onDelete, id }: ConnectProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const onRemove = () => {
    onDelete(subscriptionId, id);
    setShowConfirm(false);
  };

  return {
    onRemove,
    showConfirm,
    setShowConfirm,
  };
};
