import React, { FC, memo, useLayoutEffect, useRef } from 'react';
import { Props } from './types';
import { Container } from './styles';

const TruncateText: FC<Props> = ({ text, ...props }) => {
  const containerRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    if (container.scrollWidth > container.offsetWidth) {
      container.setAttribute('title', text);
    } else {
      container.removeAttribute('title');
    }
  }, [text]);

  return (
    <Container ref={containerRef} {...props}>
      {text}
    </Container>
  );
};

export default memo(TruncateText);
