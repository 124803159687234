import styled from 'styled-components';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';

export const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
`;

export const Icon = styled(TrashIcon)`
  height: 1.25rem;
  width: 1.25rem;
`;
