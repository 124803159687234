import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';
import { ReactComponent as HelpIcon } from 'assets/images/icons/help-circle.svg';
import styled from 'styled-components';
import { from } from 'styles/media';
import DefaultModal from 'components/Modal';
import addAlpha from 'utils/addAlpha';
import { prettyScrollbar } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  ${from.mobile} {
    & .MuiPaper-root {
      height: min(40.125rem, calc(100% - 4rem));
    }
  }

  ${from.tablet} {
    & .MuiPaper-root {
      max-width: 28rem;
    }
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  padding-right: calc(1.5rem - 0.375rem);
  margin-right: 0.375rem;
  flex: 1;
  overflow-y: scroll;
  ${prettyScrollbar({})};
`;

export const Info = styled.div`
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  display: flex;
  padding-bottom: 1.5rem;
`;

export const Placeholder = styled.span`
  height: 1.875rem;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 0.375rem;
  display: block;
`;

export const TitlePlaceholder = styled(Placeholder)`
  margin-bottom: 0.375rem;
  width: 70%;
`;

export const SubtitlePlaceholder = styled(Placeholder)`
  height: 1rem;
  width: 80%;
`;

export const NoSubject = styled.span`
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 0.3rem;
  line-height: 1.1rem;
  display: block;
`;

export const SmallPlaceholder = styled(Placeholder)`
  width: 4rem;
`;

export const PollSupport = styled.a`
  border-bottom: 0.0938rem solid transparent;
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  display: inline-flex;
  align-items: center;
  margin-right: 2rem;
  margin: 0 1rem -0.0625rem;
  padding: 0.6875rem 0.25rem;
  text-decoration: none;
`;

export const Help = styled(HelpIcon)`
  margin-left: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
`;

export const Column = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.625rem;
  width: 50%;
`;

export const Label = styled.h3`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.6875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 0.8125rem;
  letter-spacing: 0.04em;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const IconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 1.375rem;
  svg {
    height: 100%;
    width: 100%;
  }
`;
export const LinkIcon = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.gray2};
  margin-left: auto;
`;

export const Link = styled.a`
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1.125rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
  &:focus-visible {
    outline-offset: -8px;
  }
`;

export const BasicLink = styled.a`
  text-transform: none !important;
  color: inherit;
`;

export const List = styled.ul``;

export const Item = styled.li`
  margin-bottom: 2rem;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ResultsLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5625rem;
`;

export const Progress = styled.span``;

export const Text = styled.p`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.0033em;
  margin-top: 0.9375rem;
`;
