import React, { memo } from 'react';
import useLogic from './logic';
import { Component, Container, Error, Icon, Label, Optional } from './styles';
import { Props } from './types';

const CountrySelect = ({
  className,
  isDisabled,
  isOptional,
  label,
  name,
  placeholder,
  defaultValue,
  onChange,
}: Props) => {
  const { error, handleChange, hasError, options, value, onBlur } = useLogic({
    name,
    onChange,
    defaultValue,
  });

  return (
    <Container className={className}>
      <Label htmlFor={name}>
        {label} {isOptional ? <Optional>(optional)</Optional> : null}
      </Label>
      <Component
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: () => <Icon />,
          IndicatorSeparator: () => null,
        }}
        $hasValue={!!value}
        $hasError={hasError}
        id={name}
        isClearable={false}
        isDisabled={options.length === 0 || isDisabled}
        name={name}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
      />
      <Error>{hasError ? error : null}</Error>
    </Container>
  );
};

export default memo(CountrySelect);
