export type Input = {
  email: string;
  unsubscriptionTime: string;
};

export const normalizeUnsubscription = (input: Input) => {
  return {
    ...input,
    id: input.email,
    unsubscriptionTime: input.unsubscriptionTime,
  };
};

export type Unsubscription = ReturnType<typeof normalizeUnsubscription>;
