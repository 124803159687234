import React, { FC, memo, useLayoutEffect, useRef } from 'react';
import { Props } from './types';
import { Container } from './styles';

const StickySentinel: FC<Props> = ({ onSticky, ...props }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    function onIntersect(entries: IntersectionObserverEntry[]) {
      const sentinel = entries[0];

      if (sentinel.intersectionRatio === 0) {
        onSticky(true);
      }

      if (sentinel.intersectionRatio === 1) {
        onSticky(false);
      }
    }
    const observer = new IntersectionObserver(onIntersect, {
      threshold: [0, 1],
    });

    observer.observe(container);

    // eslint-disable-next-line consistent-return
    return () => {
      if (!container) return;
      observer.unobserve(container);
    };
  }, [onSticky]);

  return <Container ref={containerRef} {...props} />;
};

export default memo(StickySentinel);
