import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
`;

export const ForegroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.gray4};
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.gray3};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 160%;
  letter-spacing: -0.0066em;

  > a {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
