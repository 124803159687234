import styled, { createGlobalStyle } from 'styled-components';
import { Tooltip as DefaultTooltip } from '@material-ui/core';
import { from } from '../../../../../styles/media';
import { StyledSelect } from './PlanSelect/styles';
import { ReactComponent as DefaultInfoIcon } from '../../../../../assets/images/icons/info.svg';

export const TopBar = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 1rem;

  ${from.mobile} {
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }

  ${StyledSelect} {
    flex: 1;

    ${from.mobile} {
      max-width: 18.75rem;
    }
  }
`;

export const Header = styled.header`
  margin-bottom: 1rem;
`;

export const Tooltip = styled(DefaultTooltip).attrs((props) => ({
  arrow: true,
  placement: 'bottom',
  classes: { popper: props.className, tooltip: 'info__tooltip' },
}))``;

export const TooltipStyles = createGlobalStyle`
  .MuiTooltip-tooltip.info__tooltip {
    max-width: 15rem;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.gray3};
    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: -0.0033em;

    i {
      font-style: italic;
    }

    & .MuiTooltip-arrow {
      color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.4rem;
`;
