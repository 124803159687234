import styled from 'styled-components';
import Button from 'components/Button';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';

export const AssignSeatForm = styled.form`
  display: flex;
  flex-direction: column;

  ${from.mobile} {
    background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
    border-radius: 0.3125rem;
    border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
    flex-direction: row;

    gap: 0.5rem;
    align-items: center;
    max-width: 38.5rem;
    overflow: hidden;
    padding: 0 4px 0 14px;
    height: 40px;
    :focus-within {
      background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.25)};
      border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    }
  }
`;

export const Email = styled.div`
  background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-right: auto;
  width: 100%;
  overflow: hidden;
  position: relative;

  :focus-within {
    background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.25)};
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }
  ${from.mobile} {
    background: none;
    border: none;
    height: 100%;
    margin-bottom: 0;
    padding: 0;

    :focus-within {
      background: none;
    }
  }
`;

export const EmailInput = styled.input`
  background: none;
  border: none;
  padding: 0 14px;
  width: 100%;
  height: 46px;

  :focus {
    outline: none;
  }
  ${from.mobile} {
    height: 100%;
    padding: 0;
  }
`;

export const SubmitButton = styled(Button)`
  justify-content: center;
  padding: 0.75rem 1rem;
  min-width: 128px;
  ${from.mobile} {
    padding: 0.25rem 1rem;
  }
`;
