import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getSubscriptionSeats,
  postSubscriptionsSeat,
  deleteSubscriptionSeat as deleteSubscriptionSeatRequest,
} from '../requests/subscriptionSeats';
import { queryCache } from '../cache';

export const useGetSubscriptionSeats = (subscriptionId?: string) =>
  useQuery(['seats', subscriptionId], getSubscriptionSeats, {
    enabled: !!subscriptionId,
    staleTime: 5 * 1000 * 60,
    refetchOnWindowFocus: true,
  });

export const usePostSubscriptionSeat = () => {
  const [mutate, { status }] = useMutation(postSubscriptionsSeat);

  const createSubscriptionsSeat = useCallback(
    async (subscriptionId: string, userEmail: string) => {
      return mutate(
        { subscriptionId, userEmail },
        {
          throwOnError: true,
          onSuccess: (data, input) => {
            queryCache.invalidateQueries(['seats', input.subscriptionId]);
          },
        },
      );
    },
    [mutate],
  );

  return { createSubscriptionsSeat, status };
};

export const useDeleteSubscriptionSeat = () => {
  const [mutate, { status }] = useMutation(deleteSubscriptionSeatRequest);

  const deleteSubscriptionSeat = useCallback(
    async (subscriptionId, id) => {
      return mutate(
        {
          subscriptionId,
          id,
        },
        {
          throwOnError: true,
          onSuccess: (data, input) => {
            queryCache.invalidateQueries(['seats', input.subscriptionId]);
          },
        },
      );
    },
    [mutate],
  );

  return { deleteSubscriptionSeat, status };
};
