import { getPurchasedPlans } from 'data/requests/purchasedPlans';
import { DomainPlan } from 'model/domain';
import { normalizePurchasedPlan } from 'model/purchasedPlan';
import { useQuery } from 'react-query';

interface PurchasedPlansParams {
  userId?: string;
  userIsWorkspace?: boolean;
  domainPlan?: DomainPlan;
  domain?: string;
  filterByStatus?: 'ACTIVE' | 'CANCELLED';
}

export const usePurchasedPlans = (params?: PurchasedPlansParams) => {
  const { data, status, ...rest } = useQuery(
    ['purchasedPlans', params],
    getPurchasedPlans,
    { keepPreviousData: true },
  );

  const purchasedPlans = (data || []).map((plan) =>
    normalizePurchasedPlan(plan, params?.userIsWorkspace),
  );

  return { purchasedPlans, status, ...rest };
};
