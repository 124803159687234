import { useLayoutEffect, useState } from 'react';
import { Props } from './types';

export const useLogic = ({
  thumbnail,
  image,
}: Pick<Props, 'thumbnail' | 'image'>) => {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    setLoaded(false);
    const preview = new Image();
    preview.onload = () => setLoaded(true);
    preview.onerror = () => setLoaded(false);
    if (image) {
      preview.src = image;
    }
  }, [image]);

  // eslint-disable-next-line consistent-return
  return { preview: loaded ? image : thumbnail, loaded };
};
