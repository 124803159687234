import React from 'react';
import Bold from 'components/Bold';
import { Column } from 'components/DesktopTable/types';
import TextWrap from 'components/TextWrap';
import { Avatar, EmailCell } from './styles';
import { UserSeatActions } from '../UserSeatActions';
import { UserSeat } from '../types';

export const getColumns = ({
  onDelete,
}: {
  onDelete: (subscriptionId: string, seatId: string) => Promise<void>;
}) =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: 270,
      Cell: ({ value, row }) => {
        const {
          pictureUrl,
          initials,
          userEmail,
        } = (row.original as never) as UserSeat;
        const name = value || userEmail || '';
        return (
          <>
            <Avatar src={pictureUrl} initials={initials} />
            <TextWrap title={name.length > 24 ? name : undefined}>
              <Bold>{name}</Bold>
            </TextWrap>
          </>
        );
      },
    },
    {
      Header: 'Email',
      accessor: 'userEmail',
      width: 270,
      Cell: ({ value, row }) => {
        const { isYammUser } = (row.original as never) as UserSeat;
        return <EmailCell>{isYammUser ? value : ''}</EmailCell>;
      },
    },
    {
      Header: 'Delete',
      accessor: 'subscriptionId',
      alignment: 'right',
      width: 150,
      Cell: ({ value, row }) => {
        const { isAdmin, isYammUser, id } = (row.original as never) as UserSeat;
        return (
          <UserSeatActions
            subscriptionId={value}
            id={id}
            isAdmin={isAdmin}
            onDelete={onDelete}
            isYammUser={isYammUser}
            displayAdminTooltip
          />
        );
      },
    },
  ] as Column[];
