import Dialog from '@material-ui/core/Dialog';
import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

export const Root = styled(Dialog)`
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      top: unset !important;
    `};

  & .MuiDialog-paperScrollPaper {
    overflow-y: auto;
  }

  & .MuiPaper-elevation24 {
    box-shadow: 0rem 0.625rem 1.25rem -0.625rem ${({ theme }) => addAlpha(theme.colors.gray4, 0.7)};
  }

  & .MuiPaper-root {
    color: ${({ theme }) => theme.colors.black};
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;

    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        border-radius: 0.625rem;
        max-width: 31.25rem;
        margin: 0 1.25rem;
      `};
  }
`;
