import { client } from 'data/client';
import { Input } from 'model/scheduledCampaign';

type GetScheduledMailMergesResponse = {
  items: Input[];
};

export async function getScheduledMailMerges(key: string, userId: string) {
  const { data } = await client.get<GetScheduledMailMergesResponse>(
    `/users/${userId}/scheduledCampaigns`,
  );
  return data.items;
}
