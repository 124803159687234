export type UserSeat = {
  id: string;
  subscriptionId: string;
  userEmail: string;
  creationTime: number;
  name?: string;
  pictureUrl?: string;
};

export type SubscriptionUsers = {
  subscriptionId: string;
  users: UserSeat[];
};

export const getInitials = (userSeat: UserSeat) => {
  if (userSeat.name) {
    return userSeat.name
      ?.split(' ')
      .map((word) => word[0].toUpperCase())
      .slice(0, 2)
      .join('');
  }

  if (userSeat.userEmail) {
    return userSeat.userEmail.substr(0, 2).toUpperCase();
  }

  return '--';
};
