import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import { from } from '../../../../../../styles/media';
import addAlpha from '../../../../../../utils/addAlpha';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  ${from.tablet} {
    position: relative;
    height: 100%;
    min-width: 300px;
    padding-right: 16px;
  }
`;

export const ConfirmModal = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(${({ show }) => (show ? '0' : '100%')});
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px 12px 12px 16px;
  gap: 0.625rem;
  border-left: 1px solid #e6e5e4;
`;

export const ConfirmText = styled.span`
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.0066em;
  white-space: nowrap;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const ConfirmButton = styled.button<{ variant: 'CONFIRM' | 'ALERT' }>`
  border: none;
  background: ${({ theme, variant }) =>
    variant === 'CONFIRM' ? theme.colors.green50 : theme.colors.redLight};
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  padding: 0 0.5rem;
  cursor: pointer;
  height: 24px;

  & > span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.0066em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${({ theme, variant }) =>
      variant === 'CONFIRM' ? theme.colors.green700 : theme.colors.redDark};
  }

  & > svg {
    width: 0.9rem;
    height: 0.9rem;
    color: ${({ theme, variant }) =>
      variant === 'CONFIRM' ? theme.colors.green700 : theme.colors.redDark};
  }
`;

export const RemoveIcon = styled(TrashIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

export const AdminContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0 0.5rem;
`;

export const AdminBadge = styled.span`
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.blue800};
  background-color: ${({ theme }) => theme.colors.blue50};
  padding: 6px 10px;
  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: -0.0033em;
`;

export const InstallationPendingBadge = styled.span`
  border-radius: 5px;
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  background-color: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
  margin: 0 0.5rem;
  padding: 6px 10px;
  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.0033em;
  white-space: nowrap;
  cursor: pointer;
`;
