import React from 'react';
import { format12hTime, formatMonthDay } from 'utils/dates';
import { Column } from 'components/DesktopTable/types';
import { ScheduledCampaign } from 'model/scheduledCampaign';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { SheetLink, SheetIcon, CampaignLink } from '../../styles';
import { ErrorIcon, WarningIcon, TitleText, TitleGrid } from './styles';
import {
  isCampaignInterruptedBecauseOfQuota,
  isErrorStatus,
} from '../../../../../utils/scheduledCampaigns';

const getTitleContent = (
  value: string,
  scheduledCampaign: ScheduledCampaign,
) => {
  if (isCampaignInterruptedBecauseOfQuota(scheduledCampaign)) {
    return (
      <TitleGrid>
        <WarningIcon />
        <TitleText $variant="warning">{value}</TitleText>
      </TitleGrid>
    );
  }
  if (isErrorStatus(scheduledCampaign.status)) {
    return (
      <TitleGrid>
        <ErrorIcon />
        <TitleText $variant="error">{value}</TitleText>
      </TitleGrid>
    );
  }
  return <TitleText $variant="normal">{value}</TitleText>;
};

export const getColumns = ({ url }: { url: string }) =>
  [
    {
      Header: 'Name',
      accessor: 'title',
      width: 228,
      Cell: ({ value, row }) => {
        const campaign = row.original as ScheduledCampaign;
        return (
          <CampaignLink
            to={`${url}/${campaign.id}`}
            title={value.length > 28 ? value : undefined}
          >
            {getTitleContent(value, campaign)}
          </CampaignLink>
        );
      },
    },
    {
      Header: 'Date',
      accessor: 'mergeDate',
      width: 60,
      filter: 'unix',
      sortType: 'datetime',
      defaultSorted: 'desc',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={formatMonthDay} />
      ),
    },
    {
      Header: 'Time',
      accessor: 'mergeDateTime',
      width: 66,
      filter: 'unix',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={format12hTime} />
      ),
    },
    {
      Header: 'Emails',
      accessor: 'numberOfEmailsToSend',
      alignment: 'right',
      width: 70,
      Cell: ({ value }) => (
        <>{value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}</>
      ),
    },
    {
      Header: 'Sheet',
      accessor: 'spreadsheetLink',
      alignment: 'right',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <SheetLink href={value} target="_blank" rel="noopener noreferrer">
          <SheetIcon />
        </SheetLink>
      ),
    },
  ] as Column[];
