import { Field } from 'formik';
import React, { FC, memo } from 'react';
import testingVar from 'utils/testingVar';

import { Email, EmailInput, SubmitButton, AssignSeatForm } from './styles';
import { AssignSeatFormModel, Props } from './types';

const AssignSeatInput: FC<Props> = ({
  handleSubmit,
  isSubmitting,
  isValid,
  domain,
  values,
  ...props
}) => {
  return (
    <AssignSeatForm onSubmit={handleSubmit} {...props}>
      <Email>
        <Field
          as={EmailInput}
          data-test-id={testingVar('email')}
          id="login_input_email"
          name="email"
          placeholder={`email@${domain}`}
          type="text"
          value={values.email}
        />
      </Email>
      <SubmitButton
        data-test-id={testingVar('submit-user-email')}
        disabled={isSubmitting || !isValid}
        type="submit"
      >
        Add user
      </SubmitButton>
    </AssignSeatForm>
  );
};

export type { AssignSeatFormModel };
export default memo(AssignSeatInput);
