import styled from 'styled-components';

import { Card as GlobalCard } from '../styles';

export const Card = styled(GlobalCard)`
  width: 100%;
  max-width: 29.5rem;
`;

export const Title = styled.h2`
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.4rem;
  font-weight: 600;

  &:not(:first-of-type) {
    margin-top: 2.5rem;
  }
`;

export const Text = styled.span`
  font-size: 0.813rem;
  line-height: 1.3rem;

  b {
    font-weight: 600;
  }
`;

export const Video = styled.video`
  margin-top: 0.75rem;
  width: 21.875rem;
  min-height: 12.406rem;
  border: 0.055rem solid #d9e0e3;
  filter: drop-shadow(0 0.273rem 0.82rem rgba(0, 0, 0, 0.15));
  border-radius: 0.328rem;
  overflow: hidden;
`;

export const ButtonLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  text-decoration: none;
`;
