import React, { useRef, useCallback, memo, useMemo } from 'react';
import debounce from 'lodash.debounce';
import SearchBar from 'components/SearchBar';
import Select from 'components/Select';
import { DateFilter, FilterComponent } from 'components/DesktopTable/types';

const filterValues = [
  { value: 'LAST_30_DAYS', label: 'Last 30 days' },
  { value: 'LAST_MONTH', label: 'Last month' },
  { value: 'THIS_MONTH', label: 'This month' },
  { value: 'LAST_12_MONTHS', label: 'Last 12 months' },
  { value: 'THIS_YEAR', label: 'This year' },
];

const Search: FilterComponent = ({
  onChange,
  currentDateFilter,
  onDateFilterChange,
  goToPage,
}) => {
  const searchRef = useRef<HTMLInputElement | null>(null);
  const debouncedChange = debounce(onChange, 200);
  const defaultValue = useMemo(() => {
    return filterValues.find((filter) => filter.value === currentDateFilter);
  }, [currentDateFilter]);

  const handleChange = useCallback(
    (value: string | undefined) => {
      const change = value || searchRef.current?.value || '';
      debouncedChange(change.length > 0 ? change : undefined);
    },
    [debouncedChange],
  );

  const handleFilterChange = useCallback(
    (item: { value: DateFilter; label: string }) => {
      if (!onDateFilterChange) return;
      const { value } = item;
      onDateFilterChange(value);
      if (goToPage) {
        goToPage(0);
      }
    },
    [onDateFilterChange, goToPage],
  );

  return (
    <SearchBar
      onChange={handleChange}
      ref={searchRef}
      select={
        <Select
          onChange={handleFilterChange}
          options={filterValues}
          defaultValue={defaultValue}
        />
      }
    />
  );
};

export default memo(Search);
