import { BusinessType, getPaymentInfo } from 'data/requests/paymentInfo';
import { normalizePaymentInfo } from 'model/paymentInfo';
import { useQuery } from 'react-query';

export const usePaymentInfo = (params: {
  country?: string;
  type: BusinessType;
}) => {
  const { data, status, isFetching, isError } = useQuery(
    ['payment-info', params],
    getPaymentInfo,
    {
      enabled: params.country && params.type,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const paymentInfo = data && normalizePaymentInfo(data);
  return { paymentInfo, status, isFetching, isError };
};
