import React from 'react';
import MessageCard from 'components/MessageCard';

import StaticLayout from 'containers/Common/StaticLayout';
import testingVar from 'utils/testingVar';

const Success = () => {
  return (
    <StaticLayout data-test-id={testingVar('success')}>
      <MessageCard
        variant="success"
        title="Unsubscription was successful!"
        subtitle={
          <>
            We have informed the sender of this email that you don’t want to
            receive more emails from them.
          </>
        }
      />
    </StaticLayout>
  );
};

export default Success;
