import { getTemplates } from 'data/requests/templates';
import { useQuery } from 'react-query';
import { normalizeTemplate } from 'model/template';

export const useTemplates = () => {
  const { data, status } = useQuery('templates', getTemplates);
  const normalizedTemplates =
    data && data.sort((a, b) => a.order - b.order).map(normalizeTemplate);
  return { templates: normalizedTemplates, status };
};
