import styled, { createGlobalStyle } from 'styled-components';
import { Tooltip as DefaultTooltip } from '@material-ui/core';

export const Tooltip = styled(DefaultTooltip).attrs((props) => ({
  arrow: true,
  placement: 'bottom',
  classes: { popper: props.className, tooltip: 'info__tooltip' },
}))``;

export const TooltipStyles = createGlobalStyle`
  .MuiTooltip-tooltip.info__tooltip {
    max-width: 15rem;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.gray3};
    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: -0.0033em;

    i {
      font-style: italic;
    }

    & .MuiTooltip-arrow {
      color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;
