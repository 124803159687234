import React, { FC } from 'react';
import Loader from 'components/Loader';
import { useMediaQuery, from } from 'styles/media';
import testingVar from 'utils/testingVar';
import {
  BaseModal,
  Title,
  Thumb,
  Image,
  Header,
  Button,
  CloseButton,
  CloseIcon,
  Placeholder,
} from './styles';
import { Props } from './types';
import { useLogic } from './logic';

const TemplateModal: FC<Props> = ({
  title,
  image,
  thumbnail,
  loading,
  disabled,
  messageId,
  children,
  onImport,
  onClose,
  open,
  loggedUserIndex,
  ...rest
}) => {
  const { preview, loaded } = useLogic({ image, thumbnail });
  const isMobile = !useMediaQuery(from.tablet);
  return (
    <BaseModal
      fullScreen={isMobile}
      onClose={onClose}
      open={open}
      animation={isMobile ? 'slideUp' : 'fade'}
      {...rest}
    >
      <Thumb data-test-id={testingVar('template-modal')}>
        <Image src={preview} $loaded={loaded} />
      </Thumb>
      <Header>
        {title ? <Title>{title}</Title> : <Placeholder />}
        {!isMobile && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                {messageId ? (
                  <Button
                    forwardedAs="a"
                    href={`https://mail.google.com/mail/u/${loggedUserIndex}/#drafts?compose=${messageId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open
                  </Button>
                ) : (
                  <Button
                    data-test-id={testingVar('submit-import')}
                    variant="secondary"
                    onClick={onImport}
                    disabled={disabled}
                  >
                    Use
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </Header>
      {children}
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    </BaseModal>
  );
};

export default TemplateModal;
