import { useCallback, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik';
import { captureException } from '@sentry/react';
import { PurchasedPlan } from '../../../../../model/purchasedPlan';
import { AssignSeatFormModel } from '../../../../../components/AssignSeatInput';
import { useEnqueueSnackbar } from '../../../../../components/Snackbar/useEnqueueSnackbar';
import {
  useDeleteSubscriptionSeat,
  useGetSubscriptionSeats,
  usePostSubscriptionSeat,
} from '../../../../../data/hooks/useSubscriptionSeats';
import { UserSeat } from './types';
import {
  getInitials,
  UserSeat as UserSeatModel,
} from '../../../../../model/subscriptionSeats';
import { useMe } from '../../../../../data/hooks/useMe';
import {
  getDomainFromEmail,
  isValidEmail,
} from '../../../../../utils/userUtils';

const normalizeUserToUI = (
  userSeat: UserSeatModel,
  subscriptionId: string,
  loggedUserEmail: string,
): UserSeat => ({
  ...userSeat,
  subscriptionId,
  initials: getInitials(userSeat),
  isAdmin: userSeat.userEmail === loggedUserEmail,
  isYammUser: !!userSeat.name,
});

const existingUsersSorter = (a: UserSeat, b: UserSeat) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }

  return 0;
};

const newUsersSorter = (a: UserSeat, b: UserSeat) => {
  if (a.userEmail && b.userEmail) {
    return a.userEmail.localeCompare(b.userEmail);
  }

  return 0;
};

const sortUsersSeats = (usersSeats: UserSeat[]) => {
  const admins: UserSeat[] = [];
  const users: UserSeat[] = [];
  const notYammUsers: UserSeat[] = [];

  usersSeats.forEach((userSeat) => {
    if (userSeat.isAdmin) {
      admins.push(userSeat);
    } else if (!userSeat.isYammUser) {
      notYammUsers.push(userSeat);
    } else {
      users.push(userSeat);
    }
  });
  admins.sort(existingUsersSorter);
  users.sort(existingUsersSorter);
  notYammUsers.sort(newUsersSorter);
  return [...admins, ...users, ...notYammUsers];
};

export function useConnect({ domainPlans }: { domainPlans: PurchasedPlan[] }) {
  const enqueue = useEnqueueSnackbar();
  const { me } = useMe();
  const [selectedSubscription, setSelectedSubscription] = useState<
    PurchasedPlan | undefined
  >(domainPlans[0]);

  const { data: subscriptionUsers, status } = useGetSubscriptionSeats(
    selectedSubscription?.subscriptionId,
  );
  const { createSubscriptionsSeat } = usePostSubscriptionSeat();
  const { deleteSubscriptionSeat } = useDeleteSubscriptionSeat();

  const sortedUsers: UserSeat[] = useMemo(() => {
    if (subscriptionUsers && subscriptionUsers.users) {
      const currentUserEmail = me && me.email ? me.email : null;
      const normalized = subscriptionUsers.users.map((userSeat) =>
        normalizeUserToUI(
          userSeat,
          subscriptionUsers.subscriptionId,
          currentUserEmail,
        ),
      );

      return sortUsersSeats(normalized);
    }
    return [];
  }, [subscriptionUsers]);

  const initialValues = { email: '' };
  const onSubmit = useCallback(
    async (
      values: AssignSeatFormModel,
      { setSubmitting, resetForm, validateForm }: FormikHelpers<any>,
    ) => {
      if (
        selectedSubscription &&
        selectedSubscription.subscriptionId &&
        values.email
      ) {
        try {
          if (
            getDomainFromEmail(values.email) !== selectedSubscription?.domain
          ) {
            enqueue('The email is not in your domain.', {
              variant: 'error',
            });
            setSubmitting(false);
            return;
          }

          const hasUser = sortedUsers.find(
            (user) => user.userEmail === values.email,
          );
          if (hasUser) {
            enqueue('The user is already in your team.', {
              variant: 'warning',
            });
            setSubmitting(false);
            return;
          }

          await createSubscriptionsSeat(
            selectedSubscription.subscriptionId,
            values.email,
          );
          resetForm();
          validateForm();
          setSubmitting(false);
          enqueue('The user is now part of your team.');
        } catch (e) {
          captureException(e);
          setSubmitting(false);
          enqueue('Error during seat assignment', {
            variant: 'error',
          });
        }
      } else {
        setSubmitting(false);
      }
    },
    [selectedSubscription, createSubscriptionsSeat, enqueue, sortedUsers],
  );

  const validate = useCallback((values: Partial<AssignSeatFormModel>) => {
    const errors: Partial<AssignSeatFormModel> = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'An email is invalid';
    }
    return errors;
  }, []);

  const onPlanChange = ({ value }: { value: string }) => {
    setSelectedSubscription(
      domainPlans.find((domainPlan) => domainPlan.subscriptionId === value),
    );
  };

  const onDeleteSeat = useCallback(
    async (subscriptionId: string, seatId: string) => {
      try {
        await deleteSubscriptionSeat(subscriptionId, seatId);
        enqueue('User deleted successfully');
      } catch (e) {
        captureException(e);
        enqueue('Error during user deletion', {
          variant: 'error',
        });
      }
    },
    [],
  );

  const hasEmptySeats: boolean = useMemo(() => {
    if (selectedSubscription && selectedSubscription.seats && sortedUsers) {
      return sortedUsers.length < selectedSubscription.seats;
    }

    return false;
  }, [selectedSubscription, sortedUsers]);

  return {
    initialValues,
    onSubmit,
    validate,
    onPlanChange,
    sortedUsers,
    subscriptionUsers,
    status,
    onDeleteSeat,
    selectedSubscription,
    hasEmptySeats,
  };
}
