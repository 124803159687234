import React, { FC, memo, useEffect, useRef, useState } from 'react';
import PlaceholderImage from 'assets/images/icons/avatar-placeholder-32.png';
import { Props } from './types';
import { Container, Image as StyledImage } from './styles';

const colors = ['green', 'red', 'purple', 'orange'];

export const getRandomColor = () =>
  colors[Math.floor(Math.random() * colors.length)];

const Avatar: FC<Props> = ({ src, initials, variant, ...props }) => {
  const randomColor = useRef(getRandomColor()).current;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!src) return;
    const image = new Image();
    image.onload = () => setLoaded(true);
    image.onerror = () => setLoaded(false);
    image.src = src;
  }, [src]);

  let content = (
    <StyledImage
      src={PlaceholderImage}
      alt="User avatar placeholder"
      data-testid="avatar-placeholder"
    />
  );

  if (initials) {
    content = <>{initials}</>;
  }

  if (src && loaded) {
    content = <StyledImage src={src} alt="User avatar" data-testid="image" />;
  }

  return (
    <Container $variant={randomColor} {...props}>
      {content}
    </Container>
  );
};

export default memo(Avatar);
