import { useDomain } from 'data/hooks/useDomain';
import { useMe } from 'data/hooks/useMe';
import { useUsers } from 'data/hooks/useUsers';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getColumns } from './table/columns';

export const useConnect = () => {
  const { me } = useMe();
  const { replace } = useHistory();
  const { domain } = useDomain({ userId: me?.email, includeStats: true });
  const { users, status } = useUsers({ userId: me?.email });
  const columns = useMemo(getColumns, []);

  if (me && me.plan !== 'DOMAIN_PAID') {
    replace('/');
  }

  return {
    me,
    domain,
    users,
    status,
    columns,
  };
};
