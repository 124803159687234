import styled from 'styled-components';
import Select from 'react-select';
import addAlpha from '../../../../../../utils/addAlpha';

export const StyledSelect = styled(Select)`
  .plan-select__control {
    cursor: pointer;
    background-color: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
    border-color: ${({ theme }) => theme.colors.gray2};
    height: 40px;
  }

  .plan-select__single-value {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    display: flex;
    align-items: center;
    letter-spacing: -0.0066em;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
`;

export const StyledText = styled.span`
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray4};

  strong {
    font-weight: 600;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
`;

export const SelectUsersText = styled.span`
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray4};

  strong {
    font-weight: 600;
  }
`;

export const PlanIdText = styled.span`
  font-family: Inter;
  font-size: 13px;
  letter-spacing: -0.0066em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.gray3};
  display: block;
`;

export const StyledPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
