import React from 'react';
import { from, useMediaQuery } from 'styles/media';
import DataView from 'components/DataView';
import MobileTable from 'components/MobileTable';
import MailRow from 'components/MobileTable/MailRow';
import { Formik } from 'formik';
import testingVar from 'utils/testingVar';
import { Container, Title } from '../MailMerges/styles';
import {
  Header,
  Subtitle,
  ToolBar,
  UnsubscriptionsBar,
  SubscriptionsDesktopTable,
} from './styles';
import { useConnect } from './connect';
import Empty from './table/empty';
import Exporter from './table/exporter';

const Unsubscriptions = () => {
  const {
    columns,
    unsubscriptions,
    status,
    handle,
    initialValues,
  } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);

  return (
    <Container data-test-id={testingVar('unsubscriptions')}>
      <Header>
        <Title>Unsubscriptions</Title>
        <Subtitle>
          Enter the email addresses separated by commas, spaces or line breaks.
        </Subtitle>
      </Header>
      <ToolBar>
        <Formik
          validateOnMount
          onSubmit={handle.submit}
          initialValues={initialValues}
          validate={handle.validate}
          component={UnsubscriptionsBar}
        />
        {!isMobile && <Exporter data={unsubscriptions} />}
      </ToolBar>
      <DataView>
        {isMobile ? (
          <MobileTable
            data={unsubscriptions}
            columns={columns}
            loading={status === 'loading'}
            row={(item) => (
              <MailRow
                key={item.id}
                id={item.id}
                mail={item.email}
                date={item.unsubscriptionTime}
                onDelete={handle.delete}
              />
            )}
            empty={<Empty />}
            filter={({ children }) => <>{children}</>}
            disableFilter
          />
        ) : (
          <SubscriptionsDesktopTable
            data={unsubscriptions}
            loading={status === 'loading'}
            columns={columns}
            empty={<Empty />}
            disableFilter
          />
        )}
      </DataView>
    </Container>
  );
};

export default Unsubscriptions;
