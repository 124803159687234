import { useQuery } from 'react-query';
import { normalizeScheduledCampaign } from 'model/scheduledCampaign';
import { getScheduledMailMerge } from 'data/requests/scheduledMailMerge';

export const useScheduledMailMerge = (
  userId?: string,
  scheduledMergeId?: string,
) => {
  const { data, status } = useQuery(
    ['scheduled-mail-merge', userId, scheduledMergeId],
    getScheduledMailMerge,
    {
      enabled: !!userId && !!scheduledMergeId,
    },
  );
  const scheduledMailMerge = data && normalizeScheduledCampaign(data);
  return { scheduledMailMerge, status };
};
