import currencyjs from 'currency.js';
import { captureException } from '@sentry/minimal';

/**
 * fallback for new formatPrice
 * @param value
 * @param currency
 */
export const formatPriceLegacy = (value: number, currency: string) => {
  const symbol = (() => {
    switch (currency) {
      case 'EUR':
        return '€';
      default:
      case 'USD':
        return '$';
    }
  })();
  const formattedValue = currencyjs(value, {
    symbol,
    precision: 2,
    decimal: ',',
    separator: '.',
    pattern: '#',
  }).format();
  return symbol === '$' ? `${formattedValue} US$` : `${formattedValue} €`;
};

/**
 * format price using Intl (with fallback to old currencyjs formatting in case of error)
 * decimals are rounded. Ex: 10.155 -> 10.16
 * @param value
 * @param currency
 * @param locale: optional, pass undefined (or nothing) locale so the browser will automatically detect the user locale based on browser settings
 */
export const formatPrice = (
  value: number,
  currency: string,
  locale: string | undefined = undefined,
): string => {
  try {
    return value.toLocaleString(locale, {
      style: 'currency',
      currency,
    });
  } catch (e) {
    captureException(e);
    return formatPriceLegacy(value, currency);
  }
};
