import styled from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';

export const CheckmarkIcon = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  opacity: 0;
  transition: all 0.2s;

  path {
    fill: #fff;
  }

  &.checked {
    opacity: 1;
  }
`;

export const ProgressWrapper = styled.div`
  height: 1.875rem;
  width: 100%;
  max-width: 30rem;
  margin-bottom: 5rem;
  padding: 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  position: absolute;
  top: calc(100% + 0.938rem);
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
  line-height: 1.063rem;
  white-space: nowrap;
  color: rgba(85, 80, 78, 0.75);
  transition: all 0.2s;

  &.current {
    color: ${({ theme }) => theme.colors.blue800};
  }

  &.clickable {
    cursor: pointer;
    user-select: none;
  }
`;

export const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  flex: 0 0 auto;
  min-height: 100%;
`;

export const Circle = styled.div`
  min-width: 0.688rem;
  min-height: 0.688rem;
  border-radius: 50%;
  border: 0.063rem solid rgba(85, 80, 78, 0.5);
  transition: all 0.2s;

  &.current {
    border: 0.125rem solid ${({ theme }) => theme.colors.blue800};
  }

  &.checked {
    min-width: 1.813rem;
    min-height: 1.813rem;
    border: 0.125rem solid ${({ theme }) => theme.colors.blue800};
    background: ${({ theme }) => theme.colors.blue800};
  }

  &.clickable {
    cursor: pointer;
  }
`;

export const LineWrapper = styled.div`
  flex: 1 1 auto;
  min-height: 0.063rem;
  background: rgba(85, 80, 78, 0.25);
`;

export const Line = styled.div`
  min-height: 0.063rem;
  width: 0;
  background: ${({ theme }) => theme.colors.blue800};
  transition: all 0.2s;

  &.checked,
  &.current {
    min-width: 100%;
  }
`;
