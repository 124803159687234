import { Title, Subtitle } from 'components/DesktopTable/Empty/styles';
import React from 'react';

const ScheduledMailMergesEmpty = () => {
  return (
    <>
      <Title>Nothing to see here...</Title>
      <Subtitle>
        You don’t have any mail merge scheduled. Learn how to{' '}
        <a
          href="https://support.yet-another-mail-merge.com/hc/en-us/articles/211869705-Schedule-batch-delivery-send-emails-later"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get started
        </a>
        .
      </Subtitle>
    </>
  );
};

export default ScheduledMailMergesEmpty;
