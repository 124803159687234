import { useField } from 'formik';
import { useCallback } from 'react';
import { LogicProps, Option } from './types';

const useLogic = ({ name, onChange }: LogicProps) => {
  const [{ value }, , { setValue }] = useField<number>(name);

  const handleChange = useCallback(
    (option: Option) => {
      setValue(option.value);
      onChange(option.value);
    },
    [setValue, onChange],
  );

  return {
    handleChange,
    value,
  };
};

export default useLogic;
