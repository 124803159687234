import React, { ChangeEvent, memo, useCallback } from 'react';
import { Component, Container, Icon } from './styles';

const Select = ({
  onChange,
  seats,
}: {
  seats: number;
  onChange: (value: number) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value: nextValue } = event.currentTarget;

      if (nextValue) onChange(Number(nextValue));
    },
    [onChange],
  );

  return (
    <Container>
      <Component
        name="totalUsers"
        onChange={handleChange}
        value={seats}
        data-test-id="team-size-selector"
      >
        <option value={5}>5 users</option>
        <option value={10}>10 users</option>
        <option value={25}>25 users</option>
        <option value={50}>50 users</option>
        <option value={100}>100 users</option>
      </Component>
      <Icon />
    </Container>
  );
};

export default memo(Select);
