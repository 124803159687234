export type Input = {
  id: string;
  subject: string;
  thumbnail: string;
  preview: string;
  category: string;
  description: string;
  order: number;
};

export const normalizeTemplate = (input: Input) => {
  return { ...input };
};

export type Template = ReturnType<typeof normalizeTemplate>;
