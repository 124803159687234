import React, { FC, memo } from 'react';
import { Props } from './types';
import { ProgressBarContainer, Progress } from './styles';

const ProgressBar: FC<Props> = ({
  variant = 'blue',
  progress = 0,
  ...props
}) => {
  return (
    <ProgressBarContainer {...props}>
      <Progress variant={variant} progress={progress} />
    </ProgressBarContainer>
  );
};

export default memo(ProgressBar);
