import styled from 'styled-components';
import DefaultComponent from 'react-select';
import { ReactComponent as DefaultIcon } from 'assets/images/icons/chevron-down.svg';
import addAlpha from 'utils/addAlpha';
import { ComponentProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-bottom: 0.75rem;
`;

export const Optional = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const Component = styled(DefaultComponent)<ComponentProps>`
  & .react-select__control {
    background-color: ${({ $hasValue, theme }) =>
      addAlpha(theme.colors.gray2, $hasValue ? 0.25 : 0.5)};
    border: 0.0625rem solid
      ${({ theme, $hasError }) =>
        $hasError ? theme.colors.redDark : theme.colors.gray2};
    border-radius: 0.3125rem;
    box-shadow: none;
  }

  & .react-select__control--is-disabled {
    background-color: ${({ theme }) => addAlpha(theme.colors.gray2, 0.25)};
    border: 0.0625rem solid ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }

  & .react-select__control:hover,
  & .react-select__control--is-focused {
    border: 0.0625rem solid
      ${({ theme, $hasError }) =>
        $hasError ? theme.colors.redDark : addAlpha(theme.colors.gray3, 0.5)};
    cursor: pointer;
  }

  & .react-select__control:hover.react-select__control--menu-is-open,
  & .react-select__control--menu-is-open {
    border: 0.0625rem solid ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    border-radius: 0.3125rem;
  }

  & .react-select__input {
    color: ${({ $hasValue, theme }) =>
      theme.colors[$hasValue ? 'gray4' : 'gray3']};
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  & .react-select__menu {
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    border-radius: 0.3125rem;
    box-shadow: 0 0.25rem 0.75rem rgba(155, 155, 155, 0.2);
    margin-top: 0.3125rem;
  }

  & .react-select__option {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 1.375rem;
  }

  & .react-select__option:hover {
    cursor: pointer;
  }

  & .react-select__option.react-select__option--is-focused {
    background-color: ${({ theme }) => theme.colors.gray1};
    color: inherit;
  }

  & .react-select__option.react-select__option--is-selected {
    background-color: ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
    color: inherit;
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  & .react-select__placeholder {
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  & .react-select__single-value {
    color: inherit;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  & .react-select__value-container {
    cursor: text;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.redDark};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  min-height: 1.25rem;
  padding-top: 0.3125rem;
`;

export const Icon = styled(DefaultIcon)`
  color: inherit;
  height: 1.25rem;
  margin-right: 0.75rem;
  width: 1.25rem;
`;
