import React, { useMemo } from 'react';
import MobileTable from 'components/MobileTable';
import {
  DesktopTable,
  NoUsersTitle,
  NoUsersParagraph,
  Avatar,
  MobileRow,
  MobileUserEmailText,
  MobileUserNameText,
  MobileMiddleColumn,
  MobileTableContainer,
} from './styles';
import { getColumns } from './columns';
import { Props } from './types';
import { from, useMediaQuery } from '../../../../../../styles/media';
import { UserSeat } from '../types';
import { UserSeatActions } from '../UserSeatActions';

export function ManageUsersTable({ users, status, onDelete }: Props) {
  const columns = useMemo(() => getColumns({ onDelete }), []);
  const isMobile = !useMediaQuery(from.tablet);

  if (isMobile) {
    return (
      <MobileTableContainer>
        <MobileTable
          data={users}
          loading={status === 'loading'}
          row={(userSeat: UserSeat) => (
            <MobileRow key={userSeat.id}>
              <Avatar src={userSeat.pictureUrl} initials={userSeat.initials} />
              <MobileMiddleColumn>
                <MobileUserNameText>{userSeat.name}</MobileUserNameText>
                <MobileUserEmailText>{userSeat.userEmail}</MobileUserEmailText>
              </MobileMiddleColumn>
              <UserSeatActions
                subscriptionId={userSeat.subscriptionId}
                isAdmin={userSeat.isAdmin}
                id={userSeat.id}
                onDelete={onDelete}
                isYammUser={userSeat.isYammUser}
                displayAdminTooltip={false}
              />
            </MobileRow>
          )}
          columns={columns}
          disableFilter
        />
      </MobileTableContainer>
    );
  }

  return (
    <DesktopTable
      columns={columns}
      data={users}
      disableFilter
      loading={status === 'loading'}
      empty={
        <>
          <NoUsersTitle>Your team has no users yet</NoUsersTitle>
          <NoUsersParagraph>
            Add your collaborators by entering their email address above.
          </NoUsersParagraph>
        </>
      }
    />
  );
}
