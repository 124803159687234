import styled from 'styled-components';
import { from } from 'styles/media';
import DesktopTable from 'components/DesktopTable';
import { Header as DesktopHeader } from 'components/DesktopTable/styles';
import DefaultUnsubscriptionsBar from 'components/UnsubscriptionsBar';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 1rem;
  margin-top: 0.875rem;

  ${from.tablet} {
    margin-top: 1.3125rem;
  }
`;

export const ToolBar = styled.div`
  ${from.tablet} {
    display: flex;
  }
`;

export const UnsubscriptionsBar = styled(DefaultUnsubscriptionsBar)`
  margin-right: auto;
  flex: 1;
`;

export const DeleteButton = styled.button`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;

  :focus-visible {
    outline-offset: 2px;
  }

  > svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const SubscriptionsDesktopTable = styled(DesktopTable)`
  ${DesktopHeader} {
    top: 0;
  }
`;
