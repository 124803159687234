import styled, { css } from 'styled-components';
import { ReactComponent as DefaultErrorIcon } from 'assets/images/icons/alert-circle.svg';
import addAlpha from 'utils/addAlpha';
import { ComponentProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.gray4};
`;

export const Label = styled.label`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-bottom: 0.75rem;
`;

export const Optional = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const ComponentContainer = styled.div`
  position: relative;
`;

export const Component = styled.input<ComponentProps>`
  background-color: ${({ $hasValue, theme }) =>
    addAlpha(theme.colors.gray2, $hasValue ? 0.25 : 0.5)};
  border: 0.0625rem solid
    ${({ $hasError, theme }) =>
      $hasError ? theme.colors.redDark : theme.colors.gray2};
  border-radius: 0.3125rem;
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.375rem;
  height: 2.5rem;
  outline: 0;
  overflow: hidden;
  padding: ${({ $hasError }) =>
    $hasError ? '0.5625rem 2.5rem 0.5625rem 1rem' : '0.5625rem 1rem'};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:disabled {
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    cursor: not-allowed;
  }

  ${({ $hasError, theme }) =>
    $hasError
      ? null
      : css`
          &:focus:not(:disabled),
          &:hover:not(:disabled) {
            border: 0.0625rem solid ${addAlpha(theme.colors.gray3, 0.5)};
          }
        `}

  &::placeholder {
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }

  &:-webkit-autofill::first-line {
    color: inherit;
    font-family: Inter;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.redDark};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  min-height: 1.25rem;
  padding-top: 0.3125rem;
`;

export const ErrorIcon = styled(DefaultErrorIcon)`
  color: ${({ theme }) => theme.colors.redDark};
  height: 1.25rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
`;
