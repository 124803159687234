import styled from 'styled-components';
import DefaultLoader from 'components/Loader';
import { motion } from 'framer-motion';
import { ZIndex } from 'styles/metrics';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray1};
  z-index: ${ZIndex.SPLASH};
`;

export const Loader = styled(DefaultLoader)`
  * {
    color: ${({ theme }) => theme.colors.blue800};
  }
`;
