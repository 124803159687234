import styled from 'styled-components';

import Button from 'components/Button';

import { from } from 'styles/media';

import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';

import addAlpha from 'utils/addAlpha';

export const UnsubscribeForm = styled.form`
  ${from.mobile} {
    background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
    border-radius: 0.3125rem;
    border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
    display: flex;
    align-items: center;
    max-width: 38.5rem;
    overflow: hidden;
    padding: 0.1875rem 0.1875rem 0.1875rem 1rem;
    :focus-within {
      background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.25)};
      border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    }
  }
`;

export const Email = styled.div`
  background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: auto;
  width: 100%;
  overflow: hidden;
  position: relative;

  :focus-within {
    background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.25)};
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }
  ${from.mobile} {
    background: none;
    border: none;
    height: 100%;
    margin-bottom: 0;
    padding: 0;

    :focus-within {
      background: none;
    }
  }
`;

export const Icon = styled(PlusIcon)`
  height: 1rem;
  width: 1rem;
  position: absolute;
  left: 0.875rem;

  ${from.tablet} {
    left: 0;
  }
`;

export const EmailInput = styled.input`
  background: none;
  border: none;
  min-height: 100%;
  padding: 1rem 0.5rem 1.0625rem 2.5rem;
  width: 100%;
  :focus {
    outline: none;
  }
  ${from.mobile} {
    height: 100%;
    padding: 0.5rem 0.5rem 0.5rem 1.75rem;
  }
`;

export const SubmitButton = styled(Button)`
  justify-content: center;
  padding: 0.75rem 1rem;
  width: 100%;
  ${from.mobile} {
    max-width: 9.75rem;
    padding: 0.25rem 1rem;
  }
`;
