import styled from 'styled-components';
import { from } from 'styles/media';

import addAlpha from 'utils/addAlpha';

export const TemplatesContainer = styled.div`
  margin: 0 auto;
  max-width: 58.5rem;
  padding: 2.1875rem 0.5rem;
  color: ${({ theme }) => theme.colors.gray4};

  ${from.mobile} {
    padding-top: 2.1875rem;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 1.25rem;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-bottom: 1rem;
  max-width: 28.75rem;
`;

export const Link = styled.a`
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray3, 0.25)};
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding-bottom: 0.125rem;
  text-decoration: none;
  transition: all 0.2s ease;

  :hover {
    border-color: ${({ theme }) => theme.colors.gray3};
  }
`;

export const Alert = styled.p`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin-top: 2rem;
  padding: 0.625rem 0.75rem;
`;

export const IconWrapper = styled.span`
  background-color: ${({ theme }) => theme.colors.yellow50};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.yellow700};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 2rem;
  width: 2rem;
  margin-right: 0.75rem;

  svg {
    width: 1.1875rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin-top: 3rem;

  ${from.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
`;
