import { Link } from 'react-router-dom';
import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';

export const Data = styled.td`
  display: flex;
  flex: 1;
`;

export const Row = styled.tr`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.9375rem 1rem;
  transition: box-shadow 200ms ease-out;
  display: flex;
  position: relative;

  &:hover {
    position: relative;
    box-shadow: 0rem 0.0625rem 0.25rem rgba(107, 124, 147, 0.2);
  }

  & + & {
    border-top: 0.0625rem solid
      ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  }

  > {
    position: relative;
  }
`;

export const RowLinkContainer = styled.td``;

export const RowLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
`;
