import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { ZIndex } from 'styles/metrics';

export const Table = styled.table`
  width: 100%;
  margin-bottom: 2rem;
`;

export const FilterWrapper = styled.div`
  position: sticky;
  top: -1px;
  background: ${({ theme }) => theme.colors.gray1};
  padding-top: 1.5625rem;
  z-index: ${ZIndex.TABLE_FILTER};
  height: 4.0625rem;
`;

export const FilterContainer = styled.div`
  max-width: 58.5rem;
  margin: 0 auto;
  display: flex;
`;

export const Header = styled.thead<{ $isStuck: boolean }>`
  position: sticky;
  top: 3.4375rem;
  display: block;
  background-color: ${({ theme }) => theme.colors.gray1};
  padding: 1.5625rem 0 0.625rem 0;
  z-index: ${ZIndex.TABLE_HEADER};

  ${({ $isStuck }) =>
    $isStuck &&
    css`
      border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
    `};

  > tr {
    padding: 0 1rem;
    display: flex;
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
    font-size: 0.6875rem;
    font-weight: ${({ theme }) => theme.weights.medium};
    text-transform: uppercase;
  }
`;

export const Body = styled.tbody`
  display: block;
  margin-top: 1.125rem;
  box-shadow: 0rem 0.125rem 0.5rem rgba(107, 124, 147, 0.2);
  border-radius: 0.3125rem;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.875rem;
`;

export const Cell = styled.div<{
  $alignment: 'left' | 'right';
  $center: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ $alignment }) =>
    $alignment === 'left' ? `flex-start` : `flex-end`};

  ${({ $center }) =>
    $center
      ? css`
          margin: 0 auto;
          flex: unset !important;
        `
      : css`
          flex: 1;
        `};

  &:hover {
    > button {
      opacity: 1;
    }
  }
`;
