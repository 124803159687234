import React from 'react';
import { Formik, FormikProps } from 'formik';
import { PurchaseAdditionalMessage, Title } from '../styles';
import { useConnect } from './connect';
import { Props } from './types';
import { PlanSelect } from './PlanSelect';
import { ManageUsersTable } from './ManageUsersTable';
import { TopBar, Header, TooltipStyles } from './styles';
import AssignSeatInput, {
  AssignSeatFormModel,
} from '../../../../../components/AssignSeatInput';
import DataView from '../../../../../components/DataView';

export function ManageUsers({ domainPlans }: Props) {
  const {
    initialValues,
    onSubmit,
    validate,
    onPlanChange,
    sortedUsers,
    status,
    onDeleteSeat,
    selectedSubscription,
    hasEmptySeats,
  } = useConnect({
    domainPlans,
  });

  return (
    <>
      <TooltipStyles />
      <Header>
        <Title>Manage your users</Title>
        <PurchaseAdditionalMessage>
          No invitations are sent when adding users. All they need is to
          have&nbsp;
          <a
            href="https://support.yet-another-mail-merge.com/hc/en-us/articles/211750825-Install-Yet-Another-Mail-Merge-on-your-individual-account"
            target="_blank"
            rel="noreferrer"
          >
            YAMM installed
          </a>
          .
        </PurchaseAdditionalMessage>
      </Header>
      <TopBar>
        {domainPlans && selectedSubscription && (
          <PlanSelect
            plans={domainPlans}
            selectedSubscription={selectedSubscription}
            currentPlanUsersCount={sortedUsers.length || 0}
            onChange={onPlanChange}
          />
        )}

        {hasEmptySeats && (
          <Formik
            validateOnMount
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {(props: FormikProps<AssignSeatFormModel>) => (
              <AssignSeatInput
                {...props}
                domain={selectedSubscription?.domain || 'domain.com'}
              />
            )}
          </Formik>
        )}
      </TopBar>
      <DataView>
        <ManageUsersTable
          users={sortedUsers || []}
          status={status}
          onDelete={onDeleteSeat}
        />
      </DataView>
    </>
  );
}
