import React, { FC } from 'react';
import { Props } from './types';
import { TabsContainer, Tab } from './styles';

const Tabs: FC<Props> = ({ size = 'big', children, ...props }) => {
  return (
    <TabsContainer $size={size} {...props}>
      {children}
    </TabsContainer>
  );
};

export { Tab };
export default Tabs;
