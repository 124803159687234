/* eslint-disable import/no-webpack-loader-syntax */
import React, { memo } from 'react';
import { ExporterComponent } from 'components/DesktopTable/types';
import ExportToCsv from 'components/ExportToCsv';
import transformerWorkerUrl from 'worker-plugin/loader?name=users!../transformer';
import { useWorker } from 'utils/useWorker';
import { User } from 'model/user';
import { UsersWorker } from '../transformer';

const Exporter: ExporterComponent<User[]> = ({ data }) => {
  const { worker: transformer } = useWorker<UsersWorker>(
    transformerWorkerUrl,
    'UsersTransformer',
  );
  return (
    <ExportToCsv
      data={data}
      transformer={transformer}
      fileName={`Exported_Users_${new Date().toDateString()}`}
    />
  );
};

export default memo(Exporter);
