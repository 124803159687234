import React, { FC, forwardRef, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import testingVar from 'utils/testingVar';

import {
  ActionButton,
  Button,
  Content,
  Close,
  Container,
  Error,
  Warning,
  IconContainer,
  Message,
  Success,
} from './styles';
import { Props, IconProps } from './types';

const Icon: FC<IconProps> = ({ icon }) => {
  switch (icon) {
    case 'warning':
      return <Warning />;
    case 'error':
      return <Error />;
    default:
    case 'success':
      return <Success />;
  }
};

const Snackbar = forwardRef<HTMLDivElement, Props>(
  ({ id, message, undoId, onUndo: parentOnUndo, variant }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const onUndo = useCallback(() => {
      if (undoId && parentOnUndo) {
        parentOnUndo(undoId);
      }

      closeSnackbar();
    }, [closeSnackbar, parentOnUndo, undoId]);

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <Container
        data-test-id={testingVar('snackbar')}
        ref={ref}
        $variant={variant}
      >
        <Content>
          <Message>
            <IconContainer>
              <Icon icon={variant} />
            </IconContainer>
            {message}
          </Message>
          {undoId && (
            <ActionButton
              data-test-id={testingVar('submit-undo')}
              onClick={onUndo}
            >
              Undo
            </ActionButton>
          )}
          <Button onClick={onClose}>
            <Close />
          </Button>
        </Content>
      </Container>
    );
  },
);

export default Snackbar;
