import styled, { keyframes } from 'styled-components';

import { Button as GlobalButton, Card as GlobalCard } from '../styles';

const pulsemarketplace = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  60% {
    transform: scale(1.2, 1.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const pulsecontinue = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  60% {
    transform: scale(1.3, 1.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const Card = styled(GlobalCard)`
  width: 100%;
  max-width: 29.5rem;
`;

export const Title = styled.h2`
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.4rem;
  font-weight: 600;

  &:not(:first-of-type) {
    margin-top: 2.5rem;
  }
`;

export const Text = styled.span`
  display: block;
  max-width: 20rem;
  font-size: 0.813rem;
  line-height: 1.3rem;

  b {
    font-weight: 600;
  }
`;

export const Screenshot = styled.div`
  position: relative;
  margin-top: 0.75rem;
  margin-bottom: 1.375rem;
  max-width: 21.875rem;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    border: 0.055rem solid #d9e0e3;
    filter: drop-shadow(0 0.273rem 0.82rem rgba(0, 0, 0, 0.15));
    border-radius: 0.328rem;
  }

  svg {
    position: absolute;
    top: 1.25rem;
    right: -3.75rem;
    width: 5.625rem;
  }
`;

export const YammLink = styled.a.attrs(() => ({
  rel: 'noopener',
  target: '_blank',
  href:
    'https://workspace.google.com/marketplace/app/yet_another_mail_merge_mail_merge_for_gm/52669349336',
}))`
  cursor: pointer;
  text-decoration: none;
  color: #fff;
`;

export const Button = styled(GlobalButton)`
  z-index: 1;
  position: relative;

  &.pulse::before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 0.225rem;
    background: ${({ theme }) => theme.colors.googleButton};
    transform-origin: center;
  }

  &.pulse--marketplace::before {
    animation: ${pulsemarketplace} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &.pulse--continue::before {
    animation: ${pulsecontinue} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  }
`;
