import { parseISO, startOfDay } from 'date-fns';

export type Input = {
  id: string;
  spreadsheetId: string;
  sheetId: string;
  mergeTime?: string;
  createdTime: string;
  spreadsheetName: string;
  sheetName: string;
  numberOfEmailsSent: number;
  numberOfEmailsToSend: number;
  status: string;
  error: any;
  errorDetails: string;
  recoverableErrorRetries: number;
  recoverableErrorLastRetryTimestamp: string;
};

export const normalizeScheduledCampaign = (input: Input) => {
  return {
    ...input,
    title: `${input.spreadsheetName} - ${input.sheetName}`,
    mergeDate:
      input.mergeTime != null ? parseISO(input.mergeTime).getTime() : undefined,
    mergeDateTime:
      input.mergeTime != null ? parseISO(input.mergeTime).getTime() : undefined,
    createdDate: startOfDay(parseISO(input.createdTime)).getTime(),
    createdDateTime: parseISO(input.createdTime).getTime(),
    spreadsheetLink: `https://docs.google.com/spreadsheets/d/${input.spreadsheetId}/edit#gid=${input.sheetId}`,
    recoverableErrorLastRetryTimestamp:
      input.recoverableErrorLastRetryTimestamp != null
        ? parseISO(input.recoverableErrorLastRetryTimestamp)
        : undefined,
  };
};

export type ScheduledCampaign = ReturnType<typeof normalizeScheduledCampaign>;
