import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Billing from './Billing';
import useConnect from './connect';

const Settings = () => {
  const { path } = useConnect();

  return (
    <Switch>
      <Route component={Billing} exact path={`${path}/billing`} />
      <Redirect to={`${path}/billing`} />
    </Switch>
  );
};

export default Settings;
