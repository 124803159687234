import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';

import DefaultButton from 'components/Button';
import { Link } from 'react-router-dom';

export const TemplateCardContainer = styled.article`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0rem 0.125rem 0.5rem rgba(107, 124, 147, 0.2);
  overflow: hidden;
`;

export const Thumb = styled(Link)`
  display: flex;
  width: 100%;
  padding-top: 100%;
  position: relative;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    opacity: 0.7;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
`;

export const ImagePlaceholder = styled.span`
  background-color: ${({ theme }) => theme.colors.gray1};
  border-radius: 0.3125rem;
  height: 80%;
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const Header = styled.header`
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
`;

export const Title = styled.h2`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 160%;
  margin-right: 0.75rem;
`;

export const TitlePlaceholder = styled.span`
  background-color: ${({ theme }) => theme.colors.gray1};
  border-radius: 0.375rem;
  height: 1.375rem;
  margin-right: 0.75rem;
  width: 8.125rem;
`;

export const Button = styled(DefaultButton)`
  text-decoration: none;
`;

export const ButtonPlaceholder = styled.span`
  background-color: ${({ theme }) => theme.colors.gray1};
  border-radius: 0.25rem;
  height: 2rem;
  margin-right: 0.75rem;
  width: 3.75rem;
`;
