import React from 'react';
import testingVar from 'utils/testingVar';
import { Props } from './types';
import { Data, Row, RowLinkContainer } from './styles';
import { Cell } from '../styles';

const Rows = ({
  rows,
  prepareRow,
  originalColumns,
  rowLink: RowLink,
}: Props) => {
  return (
    <>
      {rows.map((row) => {
        prepareRow(row);
        const { key: rowKey } = row.getRowProps();
        return (
          <Row key={rowKey}>
            {RowLink && (
              <RowLinkContainer aria-hidden>
                <RowLink item={row.original} />
              </RowLinkContainer>
            )}
            {row.cells.map((cell, index, array) => {
              const isNotFirstOrLast =
                index !== 0 && index !== array.length - 1;
              const alignment = originalColumns[index].alignment ?? 'left';
              const { key } = cell.getCellProps();
              const { width: minWidth } = originalColumns[index];

              return (
                <Data key={key}>
                  <Cell
                    style={{ width: minWidth }}
                    $alignment={alignment}
                    $center={isNotFirstOrLast}
                    data-test-id={
                      key.toString().includes('title')
                        ? testingVar(`cell-name`)
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </Cell>
                </Data>
              );
            })}
          </Row>
        );
      })}
    </>
  );
};

export default Rows;
