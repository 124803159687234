export type Stat = {
  totalEmailsSent: number;
  totalEmailsSentThisMonth: number;
};

export const normalizeStat = (input: Stat) => {
  return {
    totalEmailsSent: input.totalEmailsSent ?? 0,
    totalEmailsSentThisMonth: input.totalEmailsSentThisMonth ?? 0,
  } as const;
};
