import React, { FC, memo } from 'react';
import { Props } from './types';
import { Container, GoogleLogo, GoogleLogoContainer } from './styles';

const GoogleButton: FC<Props> = ({ variant = 'SIGN_IN', ...props }) => (
  <Container $variant={variant} {...props}>
    <GoogleLogoContainer>
      <GoogleLogo />
    </GoogleLogoContainer>
    {variant === 'SIGN_IN' ? 'Sign in with Google' : null}
    {variant === 'SIGN_UP' ? 'Create a new account with Google' : null}
    {variant === 'SIGN_IN_ANOTHER_ACCOUNT'
      ? 'Sign in with another Google account'
      : null}
  </Container>
);

export default memo(GoogleButton);
