import React, { FC, memo, useCallback, useMemo } from 'react';
import { ReactComponent as Left } from 'assets/images/icons/chevron-left.svg';
import { ReactComponent as Right } from 'assets/images/icons/chevron-right.svg';
import { ReactComponent as DoubleLeft } from 'assets/images/icons/chevrons-left.svg';
import { ReactComponent as DoubleRight } from 'assets/images/icons/chevrons-right.svg';
import { nanoid } from 'nanoid';
import testingVar from 'utils/testingVar';
import { Container, Button, PageButton as StyledPageButton } from './styles';
import { Props } from './types';

function PageButton({
  active,
  onClick,
  index,
}: {
  active: boolean;
  onClick: (index: number) => void;
  index: number;
}) {
  const handleClick = useCallback(() => onClick(index), [index, onClick]);
  return (
    <StyledPageButton
      $active={active}
      onClick={handleClick}
      data-test-id={testingVar(`pagination-${index + 1}`)}
    >
      {index + 1}
    </StyledPageButton>
  );
}

function Dots({
  onClick,
  from,
  pagesCount,
}: {
  pagesCount: number;
  from: number;
  onClick: (index: number) => void;
}) {
  const handleClick = useCallback(() => {
    const half = Math.floor(from + (pagesCount - from) / 2);
    onClick(half);
  }, [onClick, from, pagesCount]);
  return (
    <StyledPageButton $active={false} onClick={handleClick}>
      ...
    </StyledPageButton>
  );
}

const LIMIT = 5;
const START = 3;

const Pagination: FC<Props> = ({
  pages,
  current,
  hasNext,
  hasPrevious,
  previousPage,
  nextPage,
  goToPage,
  ...props
}) => {
  const numbers = useMemo(
    () =>
      Array.from(Array(pages).keys()).map((_, index) => (
        <PageButton
          key={nanoid()}
          active={index === current}
          index={index}
          onClick={goToPage}
        />
      )),
    [pages, current, goToPage],
  );

  const goToFirstPage = useCallback(() => goToPage(0), [goToPage]);
  const goToLastPage = useCallback(() => goToPage(pages - 1), [
    pages,
    goToPage,
  ]);

  if (pages <= 1) return null;

  const from = Math.max(current - 1, 0);
  const to = Math.max(current + 2, START);

  let segments =
    numbers.length > LIMIT
      ? [
          ...numbers.slice(from, to),
          <Dots pagesCount={numbers.length} from={from} onClick={goToPage} />,
          numbers[numbers.length - 1],
        ]
      : numbers;

  if (numbers.length > LIMIT && current > numbers.length - START) {
    segments = numbers.slice(-START);
  }

  return (
    <Container {...props}>
      <Button
        disabled={!hasPrevious}
        onClick={goToFirstPage}
        data-test-id={testingVar(`pagination-first`)}
      >
        <DoubleLeft />
      </Button>
      <Button
        disabled={!hasPrevious}
        onClick={previousPage}
        data-test-id={testingVar(`pagination-prev`)}
      >
        <Left />
      </Button>
      {segments}
      <Button
        disabled={!hasNext}
        onClick={nextPage}
        data-test-id={testingVar(`pagination-next`)}
      >
        <Right />
      </Button>
      <Button
        disabled={!hasNext}
        onClick={goToLastPage}
        data-test-id={testingVar(`pagination-last`)}
      >
        <DoubleRight />
      </Button>
    </Container>
  );
};

export default memo(Pagination);
