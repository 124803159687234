import Select from 'react-select';
import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { ReactComponent as DownArrow } from 'assets/images/icons/arrow-down-small.svg';

const SelectStyles = css`
  .react-select-sorter__control {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.gray3};
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.weights.medium};
    line-height: 1.375rem;
    min-height: auto;
    padding: 0.75rem;
    padding-left: 0;
    box-sizing: content-box;

    &:hover {
      border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    }
  }

  .react-select-sorter__value-container {
    font-size: 0.6875rem;
    line-height: 0.8125rem;
    letter-spacing: 0.04em;
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
    padding: 0;
    text-transform: uppercase;

    .react-select-sorter__single-value {
      color: inherit;
      text-overflow: unset;
      overflow: unset;
    }
  }

  .react-select-sorter__indicator {
    color: ${({ theme }) => theme.colors.gray4};
    display: flex;
    padding: 0 0.875rem;
  }

  .react-select-sorter__menu {
    box-shadow: 0rem 0.25rem 0.75rem rgba(107, 124, 147, 0.2);
    color: inherit;
    margin-top: 0.5rem;
    overflow: hidden;
    width: 9.5rem;
  }

  .react-select-sorter__menu-list {
    padding: 0;

    .react-select-sorter__option {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.gray3};
      cursor: pointer;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 0.75rem;

      &:hover {
        background: ${({ theme }) => theme.colors.gray1};
      }

      &:active {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.gray4};
        font-weight: 500;
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  ${SelectStyles}
`;

export const DownIcon = styled(DownArrow)``;

export const UpIcon = styled(DownArrow)`
  transform: rotate(180deg);
`;
