import React, { FC, memo } from 'react';

import { ReactComponent as YammLogo } from 'assets/images/brand/YAMM-Logotype.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import { ReactComponent as AlertTriangleIcon } from 'assets/images/icons/alert-triangle.svg';
import { IconProps, Props } from './types';
import {
  MessageCardContainer,
  IconWrapper,
  Title,
  Subtitle,
  Text,
} from './styles';

const Icon: FC<IconProps> = ({ variant }) => {
  switch (variant) {
    case 'logo':
      return <YammLogo />;
    case 'success':
      return <CheckIcon color="#00955B" />;
    case 'error':
    default:
      return <AlertTriangleIcon />;
  }
};

const MessageCard: FC<Props> = ({
  title,
  subtitle,
  text,
  variant = 'logo',
  children,
  ...props
}) => {
  return (
    <MessageCardContainer $variant={variant} {...props}>
      <IconWrapper>
        <Icon variant={variant} />
      </IconWrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Text>{text}</Text>
      {children}
    </MessageCardContainer>
  );
};

export default memo(MessageCard);
