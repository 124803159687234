import { Title, Subtitle } from 'components/DesktopTable/Empty/styles';
import React from 'react';

const UsersEmpty = () => {
  return (
    <>
      <Title>Nothing to see here...</Title>
      <Subtitle>There are no users in this team</Subtitle>
    </>
  );
};

export default UsersEmpty;
