import { User } from 'model/user';
import { isGmailAddress } from 'utils/userUtils';

interface ClaritySegment {
  ui_type: string;
  is_google_workspace: string | undefined;
  is_paid: string | undefined;
  is_legacy_paid: string | undefined;
  legacy_plan: string | undefined;
}

export const ClarityUtils = {
  setup: () => {
    try {
      ((
        window: Window & typeof globalThis,
        document: Document,
        trackingCode: string,
      ) => {
        const w = window as any;
        w.clarity =
          w.clarity ||
          ((...args: any[]) => {
            (w.clarity.q = w.clarity.q || []).push(args);
          });
        const t: HTMLScriptElement = document.createElement('script');
        t.async = true;
        t.src = `https://www.clarity.ms/tag/${trackingCode}`;
        const y: HTMLScriptElement = document.getElementsByTagName('script')[0];
        y.parentNode?.insertBefore(t, y);
      })(window, document, `${process.env.REACT_APP_CLARITY_TRACKING_CODE}`);
    } catch {
      // continue regardless of error
    }
  },
  identify: (customId: string | null) => {
    try {
      if (typeof (window as any).clarity === 'function') {
        (window as any).clarity('identify', customId);
      }
    } catch {
      // continue regardless of error
    }
  },
  tag: (key: string, value: string | undefined) => {
    if (value === undefined) {
      return;
    }
    try {
      (window as any).clarity('set', key, value);
    } catch (error) {
      // continue regardless of error
    }
  },
  segment: (user: User) => {
    if (!user) return;

    const isGoogleWorkspace = !isGmailAddress(user.email);
    const isPaid = user?.plan !== 'FREE';

    let legacyPlan;
    if (isPaid && user?.plan === 'DOMAIN_PAID') {
      legacyPlan = 'team';
    } else if (isPaid && user?.plan === 'PAID') {
      legacyPlan = isGoogleWorkspace ? 'professional' : 'personal';
    }

    const segment: ClaritySegment = {
      ui_type: 'legacy',
      is_google_workspace: isGoogleWorkspace.toString(),
      is_paid: isPaid.toString(),
      is_legacy_paid: isPaid.toString(),
      legacy_plan: legacyPlan,
    };

    Object.entries(segment).forEach(([key, value]) => {
      ClarityUtils.tag(key, value);
    });
  },
};
