import { useMutation } from 'react-query';
import { deleteScheduledMailMerge } from 'data/requests/scheduledMailMerge';
import { queryCache } from 'data/cache';

export const useCancelScheduledMailMerge = () => {
  const [cancel] = useMutation(deleteScheduledMailMerge, {
    onSuccess: (_, { userId, scheduledMergeId }) => {
      queryCache.invalidateQueries(['scheduled-mail-merges', userId]);
      queryCache.invalidateQueries([
        'scheduled-mail-merge',
        userId,
        scheduledMergeId,
      ]);
    },
  });
  return cancel;
};
