export function excludeQueryParams(
  href: string,
  excludedQueryParams: string[],
): string {
  const url = new URL(href);
  excludedQueryParams.forEach((excluded) => {
    url.searchParams.delete(excluded);
  });
  return url.href;
}
