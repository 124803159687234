import React, { useState } from 'react';

export const useLogic = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    isOpen: Boolean(anchorEl),
    anchor: anchorEl,
    handleOpen,
    handleClose,
  };
};
