import styled, { css } from 'styled-components';
import DefaultBaseModal from 'components/BaseModal';
import { ReactComponent as DefaultCloseIcon } from 'assets/images/icons/close.svg';
import addAlpha from 'utils/addAlpha';
import { from } from 'styles/media';
import DefaultButton from 'components/Button';

export const BaseModal = styled(DefaultBaseModal)`
  ${from.mobile} {
    & .MuiPaper-root {
      min-width: 37.5rem;
    }
  }
`;

export const ModalHeader = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1.25rem;
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
`;

export const Thumb = styled.div`
  display: flex;
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const Image = styled.img<{ $loaded: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: filter 200ms ease-out;

  ${({ $loaded }) =>
    !$loaded &&
    css`
      filter: blur(6px);
    `};
`;

export const Placeholder = styled.span`
  height: 1.875rem;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 0.375rem;
  display: block;
  width: 20ch;
`;

export const Header = styled.header`
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
`;

export const Title = styled.h1`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 160%;
  margin-right: 0.75rem;
`;

export const Button = styled(DefaultButton)`
  text-decoration: none;
`;

export const CloseButton = styled.button`
  appearance: none;
  background: none;
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.5)};
  border: none;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  width: 100%;
  height: 100%;
`;

export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.gray3};
`;

export const Content = styled.div`
  padding: 2.5rem;
`;
