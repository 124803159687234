import React, { FC, memo } from 'react';
import { nanoid } from 'nanoid';
import { Props } from './types';
import { Container, Input, Radio } from './styles';

const RadioInput: FC<Props> = ({ className, id, ...props }) => {
  const inputId = id || nanoid(3);
  return (
    <Container className={className}>
      <Input id={inputId} {...props} type="radio" />
      <Radio htmlFor={inputId} />
    </Container>
  );
};

export default memo(RadioInput);
