import React, { FC, memo, useEffect } from 'react';
import {
  Container,
  SmallCannonEntry,
  SmallCannonTitle,
  SmallCannonSubTitle,
  SmallCannonLink,
} from './styles';
import { ReactComponent as SmallCannonIconArrowRight } from '../../assets/images/icons/small-cannon-arrow-right.svg';
import { appendUtmString } from '../../utils/smallCannonUtils';
import { Props } from './types';
import { Promotion } from '../../model/promotion';
import { useLogic } from './logic';

const SmallCannonNavBanner: FC<Props> = ({ cannon }) => {
  const { title, subtitle, logClick, logDisplay, promotions } = useLogic({
    cannon,
  });

  useEffect(() => {
    if (promotions != null) {
      logDisplay();
    }
  }, []);

  if (promotions == null) {
    return <></>;
  }

  return (
    <Container>
      <SmallCannonTitle>{title}</SmallCannonTitle>
      <SmallCannonSubTitle>{subtitle}</SmallCannonSubTitle>
      {promotions.map((entry: Promotion) => (
        <SmallCannonEntry key={entry.productId}>
          <SmallCannonIconArrowRight />
          <SmallCannonLink
            onClick={() => logClick(entry)}
            href={appendUtmString(entry.url, 'sidebar')}
            target="_blank"
          >
            {entry.name}
          </SmallCannonLink>
        </SmallCannonEntry>
      ))}
    </Container>
  );
};

export default memo(SmallCannonNavBanner);
