export type TrackingData = {
  clicked: number;
  opened: number;
  sent: number;
  unsubscribed: number;
  bounced: number;
  responded: number;
};

export const normalizeTrackingData = (input?: TrackingData) => {
  if (!input) {
    return {
      clicked: 0,
      opened: 0,
      sent: 0,
      unsubscribed: 0,
      bounced: 0,
      responded: 0,
    };
  }

  return {
    ...input,
  };
};
