import { client } from 'data/client';
import { Input } from 'model/scheduledCampaign';

type GetScheduledMergeResponse = Input;

export async function getScheduledMailMerge(
  key: string,
  userId: string,
  scheduledMergeId: string,
) {
  const { data } = await client.get<GetScheduledMergeResponse>(
    `/users/${userId}/scheduledCampaigns/${scheduledMergeId}`,
  );
  return data;
}

export async function deleteScheduledMailMerge({
  userId,
  scheduledMergeId,
}: {
  userId: string;
  scheduledMergeId: string;
}) {
  return client.delete(
    `/users/${userId}/scheduledCampaigns/${scheduledMergeId}`,
  );
}
