import styled, { css } from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/alert-triangle.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { Props } from './types';

export const IconContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  margin-right: 0.5rem;
  width: 1.5rem;
`;

const iconSizes = css`
  height: 1rem;
  width: 1rem;
`;

export const Container = styled.div<{
  $variant: Props['variant'];
}>`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.25rem 0.75rem rgba(107, 124, 147, 0.2);
    color: ${({ theme }) => theme.colors.gray4};
    max-width: 100%;
    padding: 0.75rem 0.875rem 0.75rem 1rem;
    width: 21.5rem;
  }

  ${({ $variant }) => {
    switch ($variant) {
      case 'success':
      default:
        return css`
          ${IconContainer} {
            background-color: ${({ theme }) => theme.colors.green50};
            color: ${({ theme }) => theme.colors.green700};
          }
        `;
      case 'warning':
        return css`
          ${IconContainer} {
            background-color: ${({ theme }) => theme.colors.yellow50};
            color: ${({ theme }) => theme.colors.yellow700};
          }
        `;
      case 'error':
        return css`
          ${IconContainer} {
            background-color: ${({ theme }) => theme.colors.red50};
            color: ${({ theme }) => theme.colors.red400};
          }
        `;
    }
  }}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.orange700};
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin: 0 1rem 0 auto;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.375rem;
  flex-grow: 1;
`;

export const Success = styled(CheckIcon)`
  ${iconSizes};
  color: ${({ theme }) => theme.colors.green700};
`;

export const Warning = styled(WarningIcon)`
  ${iconSizes};
`;

export const Error = styled(CloseIcon)`
  ${iconSizes};
`;

export const Button = styled.button`
  align-items: center;
  justify-self: end;
  background: none;
  border-radius: 0.25rem;
  border: none;
  color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-items: center;
  margin-left: auto;
  padding: 0;
  width: 1.25rem;

  :hover {
    background: ${({ theme }) => theme.colors.gray1};
  }
`;

export const Close = styled(CloseIcon)`
  height: 100%;
  width: 100%;
`;
