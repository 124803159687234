import { Title, Subtitle } from 'components/DesktopTable/Empty/styles';
import React from 'react';

const ScheduledMailMergesNotFound = () => {
  return (
    <>
      <Title>No results found</Title>
      <Subtitle>
        Sorry, there is no scheduled mail merge that fits with your search.
      </Subtitle>
    </>
  );
};

export default ScheduledMailMergesNotFound;
