import { useField } from 'formik';
import { LogicProps } from './types';

const useLogic = ({ name }: LogicProps) => {
  const [{ onBlur, onChange, value }, { error, touched }] = useField<string>(
    name,
  );
  const hasError = !!error && touched;

  return {
    error,
    hasError,
    onBlur,
    onChange,
    value,
  };
};

export default useLogic;
