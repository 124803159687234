import React from 'react';
import Bold from 'components/Bold';
import { Column } from 'components/DesktopTable/types';
import { User } from 'model/user';
import { formatMonthDay } from 'utils/dates';
import TextWrap from 'components/TextWrap';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { Avatar } from '../styles';

export const getColumns = () =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: 270,
      Cell: ({ value, row }) => {
        const { photo, initials } = (row.original as never) as User;
        return (
          <>
            <Avatar src={photo} initials={initials} />
            <TextWrap title={value.length > 24 ? value : undefined}>
              <Bold>{value}</Bold>
            </TextWrap>
          </>
        );
      },
    },
    {
      Header: 'Last Email',
      accessor: 'lastTimeUsed',
      width: 95,
      filter: 'unix',
      sortType: 'datetime',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={formatMonthDay} />
      ),
    },
    {
      Header: 'Sent this month',
      accessor: 'stats.totalEmailsSentThisMonth',
      alignment: 'right',
      width: 150,
      defaultSorted: 'desc',
      Cell: ({ value }) => (
        <>{value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}</>
      ),
    },
    {
      Header: 'Sent all time',
      accessor: 'stats.totalEmailsSent',
      alignment: 'right',
      width: 150,
      Cell: ({ value }) => (
        <>{value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}</>
      ),
    },
    {
      width: 120,
      id: 'spacer',
      Cell: () => null,
    },
  ] as Column[];
