import { Field } from 'formik';
import React, { FC, memo } from 'react';
import testingVar from 'utils/testingVar';

import {
  UnsubscribeForm,
  Email,
  Icon,
  EmailInput,
  SubmitButton,
} from './styles';
import { UnsubscribeFormModel, Props } from './types';

const UnsubscriptionsBar: FC<Props> = ({
  handleSubmit,
  isSubmitting,
  isValid,
  ...props
}) => {
  return (
    <UnsubscribeForm onSubmit={handleSubmit} {...props}>
      <Email>
        <Icon />
        <Field
          as={EmailInput}
          data-test-id={testingVar('email')}
          id="login_input_email"
          name="email"
          placeholder="Add email"
          type="text"
        />
      </Email>
      <SubmitButton
        data-test-id={testingVar('submit-unsubscribe')}
        disabled={isSubmitting || !isValid}
        type="submit"
      >
        Unsubscribe
      </SubmitButton>
    </UnsubscribeForm>
  );
};

export type { UnsubscribeFormModel };
export default memo(UnsubscriptionsBar);
