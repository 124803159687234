import React, { memo } from 'react';
import Component from 'components/RadioInput';
import useConnect from './connect';
import { Container, Content, Error, InputContainer, Label } from './styles';
import { Props } from './types';

const RadioInput = ({ className, label, name, onChange }: Props) => {
  const { error, handleChange, hasError, value } = useConnect({
    name,
    onChange,
  });

  return (
    <Container className={className}>
      <legend>{label}</legend>
      <Content>
        <InputContainer>
          <Component
            checked={value}
            id={`${name}-yes`}
            name={name}
            onClick={() => handleChange(true)}
          />
          <Label htmlFor={`${name}-yes`}>Yes</Label>
        </InputContainer>
        <InputContainer>
          <Component
            checked={!value}
            id={`${name}-no`}
            name={name}
            onClick={() => handleChange(false)}
          />
          <Label htmlFor={`${name}-no`}>No</Label>
        </InputContainer>
      </Content>
      <Error>{hasError ? error : null}</Error>
    </Container>
  );
};

export default memo(RadioInput);
