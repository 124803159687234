import React from 'react';
import MessageCard from 'components/MessageCard';
import { Link } from 'react-router-dom';

import StaticLayout from 'containers/Common/StaticLayout';

const Success = () => {
  return (
    <StaticLayout>
      <MessageCard
        variant="success"
        title="Autorenewal stopped successfully"
        subtitle={
          <>
            Thanks for using YAMM. You can always check your payments from{' '}
            <Link
              to="https://www.paypal.com/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              PayPal
            </Link>
            .
          </>
        }
      />
    </StaticLayout>
  );
};

export default Success;
