export default {
  fonts: {
    inter: 'Inter',
    lexendDeca: 'Lexend Deca',
    roboto: 'Roboto',
  },
  colors: {
    // Gray scale
    white: '#ffffff',
    gray1: '#F9F7F5',
    gray2: '#DEDCDB',
    gray3: '#55504E',
    gray4: '#242221',
    black: '#000000',

    // Primary
    orange50: '#F9EAE9',
    orange100: '#FCCEC3',
    orange200: '#FBAF9C',
    orange300: '#FA9076',
    orange400: '#FB7858',
    orange500: '#FB613E',
    orange600: '#F05B3A',
    orange700: '#E25535',
    orange800: '#D34F31',
    orange900: '#B8442A',

    // Secondary
    blue50: '#E1F3F5',
    blue100: '#B3E1E6',
    blue200: '#85CDD8',
    blue300: '#5EB9CA',
    blue400: '#44ABC3',
    blue500: '#2D9DBD',
    blue600: '#2490B0',
    blue700: '#177E9F',
    blue800: '#166E8C',
    blue900: '#0B4F6C',
    blue950: '#1A4159',

    // Success
    green50: '#E4F6EE',
    green100: '#BDE9D4',
    green400: '#20C289',
    green700: '#00955B',

    // Warning
    yellow50: '#FDF7E3',
    yellow100: '#FAEAB7',
    yellow400: '#F2C641',
    yellow700: '#EE9E2A',

    // Error
    redLight: '#FFE8E8', // aka gray0
    redDark: '#EB3E3E',
    red50: '#FFECEF',
    red400: '#F35759',
    red600: '#EA3E40',

    // Palette
    purple: '#8876EF',
    blue: '#3DA3EA',
    green: '#60C076',
    yellow: '#F9CF58',
    orange: '#F19648',
    red: '#E5586F',
    stripedGray:
      'repeating-linear-gradient(45deg,#fff,#fff 2px,#EEEEEE 2px,#EEEEEE 5px)',

    // Google button
    googleButton: '#4285F4',

    suvaGrey: '#767574',
    whisper: '#f9f7f6',
    cowboy: '#3f3c3c',
  },
  weights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
