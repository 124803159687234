import { client } from 'data/client';
import { Input } from 'model/customerPortal';

const API_VERSION = 'v2';

type GetCustomerPortalResponse = Input;

export function getManagePlans(
  key: string,
  userId: string,
  subscriptionIds: string[],
  returnUrl: string,
) {
  const subscriptions = subscriptionIds.map((subscriptionId) =>
    client
      .get<GetCustomerPortalResponse>(`/users/${userId}/stripeCustomerPortal`, {
        params: { returnUrl, subscriptionId },
        baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
      })
      .then(({ data }) => ({
        subscriptionId,
        link: data.stripeCustomerPortalUrl,
      })),
  );
  return Promise.all(subscriptions);
}
