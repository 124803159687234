import styled from 'styled-components';
import DefaultDesktopTable from '../../../../../../components/DesktopTable';
import { Row } from '../../../../../../components/DesktopTable/Rows/styles';
import addAlpha from '../../../../../../utils/addAlpha';
import DefaultAvatar from '../../../../../../components/Avatar';
import { Header } from '../../../../../../components/DesktopTable/styles';
import { MobileTableContainer as DefaultMobileTableContainer } from '../../../../../../components/MobileTable/styles';

export const Avatar = styled(DefaultAvatar)`
  margin-right: var(--avatarMargin);
  flex-shrink: 0;
`;

export const DesktopTable = styled(DefaultDesktopTable)`
  --horizontalPadding: 1rem;
  --avatarMargin: 0.75rem;
  --avatarSize: 2rem;

  ${Row} {
    padding: 0;
    position: relative;
    border-top: 0;

    &:hover {
      box-shadow: none;
    }

    &:not(:first-child):before {
      content: '';
      right: 0;
      position: absolute;
      top: -0.0625rem;
      width: calc(
        100% - var(--horizontalPadding) - var(--avatarSize) -
          var(--avatarMargin)
      );
      height: 1px;
      background-color: ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
    }
  }

  td:not(:last-child) {
    padding: 0.5rem 0;
  }

  td:first-child {
    padding-left: var(--horizontalPadding);
  }

  ${Header} {
    display: none;
  }
`;

export const EmailCell = styled.span`
  font-family: Inter;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.0066em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.gray3};
`;

export const NoUsersTitle = styled.span`
  font-family: Inter;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.gray4};
`;

export const NoUsersParagraph = styled.span`
  font-family: Inter;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.0066em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.gray3};
  margin-top: 0.5rem;
`;

export const MobileRow = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.875rem 0.75rem;
  gap: 0.75rem;

  :not(:last-child) {
    border-bottom: 0.0625rem solid
      ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  }
`;

export const MobileMiddleColumn = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

export const MobileUserNameText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.gray4};
`;

export const MobileUserEmailText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.0033em;
  color: ${({ theme }) => theme.colors.gray3};
`;

export const MobileTableContainer = styled.div`
  ${DefaultMobileTableContainer} {
    margin: 0;
  }
`;
