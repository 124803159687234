import styled from 'styled-components';

export const Container = styled.fieldset`
  display: flex;
  flex-direction: column;

  & legend {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
    margin-bottom: 0.75rem;
  }
`;

export const InputContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  gap: 0 1.5625rem;
  height: 2.5rem;
`;

export const Label = styled.label`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-left: 0.5625rem;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.redDark};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  min-height: 1.25rem;
  padding-top: 0.3125rem;
`;
