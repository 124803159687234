import { useQuery } from 'react-query';
import { getCannon } from '../requests/getCannon';

export function useCannon(placement: string): any {
  const { data, ...rest } = useQuery(['webapp', placement], getCannon, {
    refetchOnMount: false,
    retry: false,
  });
  return { cannon: data, ...rest };
}
