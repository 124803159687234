import Modal from 'components/Modal';
import React from 'react';
import { useQuotaConditionsModalTrigger } from './useQuotaConditionsModalTrigger';
import { CloseImage, Li, Paragraph, StyledLink, Ul, Container } from './styles';

const QuotaConditionsModal = () => {
  const { trigger, isOpen } = useQuotaConditionsModalTrigger();

  return (
    <Modal open={isOpen} onClose={trigger}>
      <CloseImage onClick={trigger} />
      <Container>
        <Paragraph>
          The <b>1,500 recipients</b> quota applies to Google Workspace accounts
          that meet the following conditions:
        </Paragraph>
        <Ul>
          <Li>
            Your Google Workspace account is{' '}
            <b>
              no longer{' '}
              <StyledLink
                href="https://support.google.com/a/answer/166852"
                target="_blank"
              >
                in its trial period
              </StyledLink>
            </b>{' '}
            and has started an active subscription
          </Li>
          <Li>
            Your Google Workspace account is not a free legacy edition account
          </Li>
          <Li>
            You haven’t sent more than 500 emails in the last 24 hours from your
            Google Workspace account
          </Li>
          <Li>
            Your Google Workspace account hasn&#34;t been rate-limited by Google
          </Li>
        </Ul>
        <Paragraph>
          To know more, please read{' '}
          <StyledLink
            href="https://support.yet-another-mail-merge.com/hc/en-us/articles/212019925-Why-is-your-quota-lower-than-expected-"
            target="_blank"
          >
            this documentation article
          </StyledLink>
        </Paragraph>
      </Container>
    </Modal>
  );
};

export default QuotaConditionsModal;
