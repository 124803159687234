import styled from 'styled-components';
import { ReactComponent as DefaultIcon } from 'assets/images/icons/lock.svg';
import addAlpha from 'utils/addAlpha';

export const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Icon = styled(DefaultIcon)`
  color: inherit;
  height: 1rem;
  margin-right: 0.125rem;
  width: 1rem;
`;

export const Text = styled.p`
  color: inherit;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  margin-bottom: 0.125rem;
  text-align: center;
`;
