import { client } from 'data/client';
import { Input } from 'model/purchasedPlan';

const API_VERSION = 'v2';

type GetPurchasedPlansResponse = {
  items: Input[];
};

interface PurchasedPlansDto {
  userId?: string;
  filterByStatus?: 'ACTIVE' | 'CANCELLED';
}

export async function getPurchasedPlans(
  key: string,
  params: PurchasedPlansDto,
) {
  const userId = params?.userId || 'me';
  const status = params?.filterByStatus || 'ACTIVE';
  const { data } = await client.get<GetPurchasedPlansResponse>(
    `/users/${userId}/purchasedPlans`,
    {
      params: { status },
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
    },
  );
  return data.items;
}
