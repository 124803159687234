import CircularProgress from '@material-ui/core/CircularProgress';
import addAlpha from 'utils/addAlpha';

import styled from 'styled-components';

export const LoaderContainer = styled.div`
  padding: 0.4375rem 0.875rem;
`;

export const Progress = styled(CircularProgress)`
  && {
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  }
`;
