import { queryCache } from 'data/cache';
import { importDraft } from 'data/requests/templates';
import { useMutation } from 'react-query';

export const useImportDraft = () => {
  const [mutate, { status }] = useMutation(importDraft, {
    onSuccess: () => {
      queryCache.invalidateQueries('templates');
    },
  });

  return { mutate, status };
};
