import { useMailMerge } from 'data/hooks/useMailMerge';
import { useMailMergePoll } from 'data/hooks/useMailMergePoll';
import { useMe } from 'data/hooks/useMe';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { formatShortDateTime } from 'utils/dates';
import { up } from 'utils/Paths';

export const useConnect = () => {
  const { push, goBack } = useHistory();
  const { url } = useRouteMatch();
  const matches = useRouteMatch<{ id: string }>(`${url}/:id`);
  const campaignId = matches?.params.id;
  const { me } = useMe();
  const { mailMerge, status } = useMailMerge(me?.email, campaignId);
  const { poll } = useMailMergePoll(me?.email, campaignId);

  const onClose = useCallback(() => {
    const currentUrl = matches?.url;
    if (!currentUrl) {
      goBack();
    } else {
      push(up(currentUrl));
    }
  }, [push, goBack, matches]);

  const lastEmailSent =
    mailMerge && formatShortDateTime(mailMerge.lastTimeUsed);

  return {
    status,
    url: matches ? matches.url : '',
    isOpen: !!matches,
    handle: {
      close: onClose,
    },
    mailMerge,
    lastEmailSent,
    poll,
  };
};
