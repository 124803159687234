import { Title, Subtitle } from 'components/DesktopTable/Empty/styles';
import React from 'react';

const MailMergesEmpty = () => {
  return (
    <>
      <Title>Nothing to see here...</Title>
      <Subtitle>
        You don’t have any mail sent. Learn how to{' '}
        <a
          href="https://support.yet-another-mail-merge.com/hc/en-us/articles/115003400145-Send-your-first-email-campaign-with-YAMM-and-Gmail"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get started
        </a>
        .
      </Subtitle>
    </>
  );
};

export default MailMergesEmpty;
