import { getStripeCheckoutSession } from 'data/requests/stripeCheckoutSession';
import { Input } from 'model/stripeCheckoutSession';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

export const useStripeCheckoutSession = () => {
  const [mutate, { status }] = useMutation(getStripeCheckoutSession);

  const getStripeSessionId = useCallback(
    async (input: Input) => {
      return mutate(input, { throwOnError: true });
    },
    [mutate],
  );

  return { getStripeSessionId, status };
};
