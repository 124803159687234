import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { ReactComponent as LogoImage } from 'assets/images/icons/yamm-logotype.svg';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ZIndex } from 'styles/metrics';

export const Container = styled.nav<{ $open: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 75.5%;
  background-color: ${({ theme }) => theme.colors.blue950};
  transform: translateX(-105%);
  box-shadow: 0rem 0.25rem 0.75rem rgba(31, 35, 48, 0.2);
  transition: transform 200ms ease-in-out;
  z-index: ${ZIndex.MOBILE_MENU};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ $open }) =>
    $open &&
    css`
      transform: none;
    `};
`;

export const Overlay = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => addAlpha(theme.colors.gray4, 0.7)};
  z-index: ${ZIndex.MOBILE_MENU_OVERLAY};
`;

export const TopSection = styled.div`
  padding: 0 1rem;
`;

export const BottomSection = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.white, 0.25)};
  padding: 0.75rem 0;
  margin-bottom: 1.1875rem;
  min-height: 3.6875rem;
`;

export const Logo = styled(LogoImage)`
  margin-right: 1rem;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
`;

export const Navigation = styled.ul`
  padding: 0.75rem 0;

  > * + * {
    margin-top: 1.875rem;
  }
`;

export const ItemIcon = styled.div`
  margin-right: 1.375rem;
`;

export const Item = styled(NavLink).attrs(() => ({
  activeClassName: 'active',
}))`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  text-decoration: none;

  &.active {
    opacity: 1;
    > ${ItemIcon} {
      color: ${({ theme }) => theme.colors.orange400};
    }
  }
`;
