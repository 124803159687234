import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import addAlpha from 'utils/addAlpha';
import { ReactComponent as ErrorIconSvg } from 'assets/images/icons/alert-circle.svg';
import { ReactComponent as WarningIconSvg } from 'assets/images/icons/alert-triangle.svg';
import { Name } from '../styles';

export const RowLinkContainer = styled.li`
  :not(:last-child) {
    border-bottom: 0.0625rem solid
      ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  }
`;

export const Link = styled(DefaultLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding: 0.875rem 0.75rem;
  row-gap: 0.3125rem;
  text-decoration: none;
`;

export const ErrorIcon = styled(ErrorIconSvg)`
  color: ${({ theme }) => theme.colors.red600};
  height: 1.125rem;
  width: 1.125rem;
`;

export const WarningIcon = styled(WarningIconSvg)`
  color: ${({ theme }) => theme.colors.yellow700};
  height: 1.125rem;
  width: 1.125rem;
`;

export const TitleText = styled(Name)<{
  $variant: 'error' | 'warning' | 'normal';
}>`
  color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'error':
        return theme.colors.red600;
      case 'warning':
        return theme.colors.yellow700;
      default:
        return theme.colors.black;
    }
  }};
`;

export const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: 1.125rem 1fr;
  grid-gap: 0.6rem;
  align-items: center;
`;
