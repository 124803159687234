import styled from 'styled-components';

import { from } from 'styles/media';

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import { ReactComponent as XCircleIcon } from 'assets/images/icons/x-circle.svg';

import addAlpha from 'utils/addAlpha';

export const SearchBarContainer = styled.div`
  background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.5)};
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
  display: flex;
  margin-right: auto;
  max-width: 100%;
  padding: 0.8125rem 0;

  ${from.mobile} {
    padding: 0.5625rem 0;
    max-width: 38.5rem;
  }

  :focus-within {
    background: ${({ theme }) => addAlpha(theme.colors.gray2, 0.25)};
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.8125rem 0 1rem;
  width: 100%;

  ${from.tablet} {
    width: 28.5rem;
  }
`;

export const Icon = styled(SearchIcon)`
  height: 1rem;
  width: 1rem;
  position: absolute;
`;

export const ClearButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  :focus {
    outline: none;
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }
`;

export const ClearIcon = styled(XCircleIcon)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const SearchInput = styled.input`
  background: none;
  border: none;
  min-height: 100%;
  padding: 0 0.5rem 0 1.75rem;
  position: relative;
  width: 100%;
  height: 1.25rem;

  :focus {
    outline: none;
  }
`;
