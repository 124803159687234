import React, { memo } from 'react';
import {
  Button,
  CloseIcon,
  Container,
  Content,
  ErrorIcon,
  IconContainer,
  Message,
  SuccessIcon,
  TextContainer,
  Title,
  WarningIcon,
} from './styles';
import { Props } from './types';

const BillingMessage = ({
  className,
  isOpen,
  message,
  onClose,
  title,
  variant,
  id,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Container className={className} data-test-id={id}>
      <Button onClick={onClose} title="Close message">
        <CloseIcon />
      </Button>
      <Content>
        <IconContainer $variant={variant}>
          {variant === 'ERROR' ? <ErrorIcon /> : null}
          {variant === 'SUCCESS' ? <SuccessIcon /> : null}
          {variant === 'WARNING' ? <WarningIcon /> : null}
        </IconContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Message>{message}</Message>
        </TextContainer>
      </Content>
    </Container>
  );
};

export default memo(BillingMessage);
