import { ScheduledCampaign } from '../../model/scheduledCampaign';

export const ERROR_MESSAGES: { [key: string]: string } = {
  TOO_COMPLEX_REGEXP: 'TOO COMPLEX REGEXP',
  SPREADSHEET_DELETED: 'SPREADSHEET DELETED',
  INVALID_DRAFT: 'INVALID DRAFT',
  UNKNOWN_ERROR: 'UNKNOWN ERROR',
  DRAFT_NOT_FOUND: 'DRAFT NOT FOUND',
  NAMED_RANGE_DELETED: 'NAMED RANGE DELETED',
  USER_PROPERTY_NOT_FOUND: 'USER PROPERTY NOT FOUND',
  SHEET_DELETED: 'SHEET DELETED',
};

/**
 * convert error values to list so it can be checked in isErrorStatus
 */
const ERRORS_LIST: string[] = Object.values(ERROR_MESSAGES);

export const SCHEDULED_MERGE_RECOVERABLE_ERRORS_MAX_RETRIES = 5;

/**
 * check if scheduled campaign sending will be attempted again
 * @param scheduledCampaign
 */
export const willRetryScheduledMergeInError = (
  scheduledCampaign: ScheduledCampaign,
): boolean =>
  !!(
    scheduledCampaign.recoverableErrorLastRetryTimestamp &&
    scheduledCampaign.recoverableErrorRetries &&
    scheduledCampaign.recoverableErrorRetries <
      SCHEDULED_MERGE_RECOVERABLE_ERRORS_MAX_RETRIES
  );

/**
 * Check if status is error
 * @param status
 */
export const isErrorStatus = (status: string): boolean =>
  status != null && ERRORS_LIST.includes(status);

/**
 * check if scheduled campaign is interrupted because of quota
 * @param scheduledCampaign
 */
export const isCampaignInterruptedBecauseOfQuota = (
  scheduledCampaign: ScheduledCampaign,
): boolean =>
  scheduledCampaign.mergeTime == null &&
  scheduledCampaign.status === 'IN PROGRESS';
