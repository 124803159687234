import { useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { up } from 'utils/Paths';
import { useMe } from 'data/hooks/useMe';
import { useScheduledMailMerge } from 'data/hooks/useScheduledMailMerge';
import { formatShortDateTime } from 'utils/dates';
import { useCancelScheduledMailMerge } from 'data/hooks/useCancelScheduledMailMerge';
import { useEnqueueSnackbar } from 'components/Snackbar/useEnqueueSnackbar';
import { captureException } from '@sentry/minimal';
import { useStackDriverLogging } from 'data/context/StackDriverLogging';
import { getUserDataForLogging } from 'utils/logging';
import {
  isCampaignInterruptedBecauseOfQuota,
} from 'utils/scheduledCampaigns';

export const useConnect = () => {
  const { push, goBack } = useHistory();
  const enqueue = useEnqueueSnackbar();
  const { url } = useRouteMatch();
  const matches = useRouteMatch<{ id: string }>(`${url}/:id`);
  const scheduledMergeId = matches?.params.id;
  const { me } = useMe();
  const { scheduledMailMerge } = useScheduledMailMerge(
    me?.email,
    scheduledMergeId,
  );
  const cancel = useCancelScheduledMailMerge();
  const deliveryDate =
    scheduledMailMerge &&
    scheduledMailMerge.mergeDate &&
    formatShortDateTime(scheduledMailMerge.mergeDate);
  const { logToStackDriver, getUser } = useStackDriverLogging();
  const onClose = useCallback(() => {
    const currentUrl = matches?.url;
    if (!currentUrl) {
      goBack();
    } else {
      push(up(currentUrl));
    }
  }, [push, goBack, matches]);

  const cancelScheduledMailMerge = useCallback(async () => {
    if (!me || !scheduledMergeId) return;
    let commit = true;
    const performCancel = async () => {
      if (!commit) return;
      try {
        await cancel({ userId: me.email, scheduledMergeId });
        enqueue('Mail merge cancelled.');
      } catch {
        enqueue('An error ocurred. Please try again.', { variant: 'error' });
        return;
      }
      if (!scheduledMailMerge) {
        return;
      }
      // log the canceled merge to stackdriver
      try {
        const user = getUserDataForLogging(getUser());
        logToStackDriver({
          merge_id: `${scheduledMailMerge.mergeDate}_${scheduledMailMerge.spreadsheetId}`,
          campaign_start_time: scheduledMailMerge.mergeDateTime,
          created_time: scheduledMailMerge.createdDateTime,
          spreadsheet_id: scheduledMailMerge.spreadsheetId,
          spreadsheet_name: scheduledMailMerge.spreadsheetName,
          sheet_id: scheduledMailMerge.sheetId,
          sheet_name: scheduledMailMerge.sheetName,
          timestamp: Date.now(),
          nb_emails_sent: scheduledMailMerge.numberOfEmailsSent,
          nb_emails_to_send: scheduledMailMerge.numberOfEmailsToSend,
          user_id: user.user_id,
          user_installation_timestamp: user.user_installation_timestamp,
          plan: user.plan,
          is_biz_user: user.is_biz_user,
          domain: user.domain,
          event_type: 'scheduled_merge_canceled_by_user',
          message:
            'User canceled a scheduled merge (scheduled_merge_canceled_by_user)',
        });
      } catch (e) {
        captureException(e);
      }
    };
    enqueue('Cancelling scheduled merge...', {
      autoHideDuration: 3000,
      onExited: performCancel,
      undoId: `cancel-merge-${scheduledMergeId}`,
      onUndo: () => {
        commit = false;
      },
    });
    onClose();
  }, [cancel, me, scheduledMergeId, onClose, enqueue]);

  const isOutOfQuota = useMemo(
    () =>
      scheduledMailMerge &&
      isCampaignInterruptedBecauseOfQuota(scheduledMailMerge),
    [scheduledMailMerge],
  );

  return {
    scheduledMailMerge,
    isOutOfQuota,
    deliveryDate,
    cancelScheduledMailMerge,
    url: matches ? matches.url : '',
    isOpen: !!matches,
    handle: {
      close: onClose,
    },
  };
};
