import React from 'react';
import Bold from 'components/Bold';
import { Column } from 'components/DesktopTable/types';
import { formatShortMonthDayYear } from 'utils/dates';
import { Unsubscription } from 'model/unsubscription';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import TextWrap from 'components/TextWrap';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import testingVar from 'utils/testingVar';
import { DeleteButton } from '../styles';

export const getColumns = ({
  onDelete,
}: {
  onDelete: (unsubscriptionId: string) => void;
}) =>
  [
    {
      Header: 'Email',
      accessor: 'email',
      defaultSorted: 'asc',
      Cell: ({ value }) => (
        <TextWrap title={value.length > 40 ? value : undefined}>
          <Bold>{value}</Bold>
        </TextWrap>
      ),
      width: 300,
    },
    {
      Header: 'Date',
      accessor: 'unsubscriptionTime',
      width: 100,
      filter: 'unix',
      sortType: 'datetime',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={formatShortMonthDayYear} />
      ),
    },
    {
      Header: 'Delete',
      id: 'delete-action',
      alignment: 'right',
      Cell: ({ row }) => {
        const unsubscriptionId = ((row.original as never) as Unsubscription).id;
        const handleClick = () => onDelete(unsubscriptionId);
        return (
          <DeleteButton
            data-test-id={testingVar('submit-delete')}
            type="button"
            onClick={handleClick}
          >
            <TrashIcon />
          </DeleteButton>
        );
      },
    },
  ] as Column[];
