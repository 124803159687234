import TemplateCard from 'components/TemplateCard';
import React, { useMemo } from 'react';
import { from, useMediaQuery } from 'styles/media';
import { ReactComponent as AlertTriangleIcon } from 'assets/images/icons/alert-triangle.svg';
import { nanoid } from 'nanoid';
import testingVar from 'utils/testingVar';
import {
  TemplatesContainer,
  Title,
  Description,
  Link,
  Grid,
  Alert,
  IconWrapper,
} from './styles';
import { useConnect } from './connect';
import TemplateModal from './TemplateModal';

const Templates = () => {
  const {
    importingId,
    templates,
    url,
    isModalOpen,
    openedTemplate,
    importedDrafts,
    handle,
    loggedUserIndex,
  } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);
  const placeholder = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map(() => <TemplateCard loading={false} key={nanoid(3)} />),
    [],
  );

  return (
    <>
      <TemplatesContainer data-test-id={testingVar('templates')}>
        <Title>Templates</Title>
        <Description>
          Select a template and it will save as a Gmail draft for you to use how
          you want. You can start also in Google Docs.
        </Description>
        <Link
          href="https://support.yet-another-mail-merge.com/hc/en-us/articles/214119185"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Learn how
        </Link>
        {isMobile && (
          <Alert data-test-id={testingVar('desktop-only-message')}>
            <IconWrapper>
              <AlertTriangleIcon />
            </IconWrapper>
            To use one of these templates, please visit the desktop version.
          </Alert>
        )}
        <Grid>
          {templates
            ? templates.map((template) => (
                <TemplateCard
                  key={template.id}
                  id={template.id}
                  onImport={handle.import}
                  messageId={importedDrafts[template.id]}
                  loggedUserIndex={loggedUserIndex}
                  loading={template.id === importingId}
                  disabled={importingId !== undefined}
                  image={template.thumbnail}
                  title={template.subject}
                  url={`${url}/${template.id}`}
                />
              ))
            : placeholder}
        </Grid>
      </TemplatesContainer>
      <TemplateModal
        onClose={handle.close}
        open={isModalOpen}
        messageId={
          openedTemplate ? importedDrafts[openedTemplate.id] : undefined
        }
        loggedUserIndex={loggedUserIndex}
        onImport={handle.importFromModal}
        loading={openedTemplate?.id === importingId}
        disabled={importingId !== undefined}
        thumbnail={openedTemplate?.thumbnail}
        image={openedTemplate?.preview}
        title={openedTemplate?.subject}
      />
    </>
  );
};

export default Templates;
