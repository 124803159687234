import styled from 'styled-components';

import { from } from 'styles/media';

import { Link } from 'react-router-dom';

import { ReactComponent as YammLogo } from 'assets/images/brand/YAMM-Logotype.svg';

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.gray1};
  padding: 1rem 0.75rem;
  position: fixed;
  width: 100%;

  ${from.mobile} {
    padding: 1.25rem 2rem;
  }
`;

export const Brand = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-size: 1rem;
  letter-spacing: -0.01em;
  line-height: 1.625rem;
  text-decoration: none;
`;

export const Logo = styled(YammLogo)`
  margin-right: 1rem;
  height: 2rem;
  width: 2rem;

  ${from.mobile} {
    margin-right: 0.75rem;
  }
`;

export const Main = styled.main`
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 5rem 0;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.75rem;
  letter-spacing: -0.0033em;
  line-height: 1.0625rem;
  margin: 1.5rem auto 0;
  max-width: 20rem;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
