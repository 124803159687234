import styled, { createGlobalStyle } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { Tooltip as DefaultTooltip } from '@material-ui/core';
import { ReactComponent as DefaultInfoIcon } from 'assets/images/icons/info.svg';

export const Container = styled.div`
  padding: 2rem;
  border-radius: 0.3125rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const PlanSummary = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 160%;
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const PlanItem = styled.li`
  list-style: none;
  color: ${({ theme }) => theme.colors.gray3};
  letter-spacing: -0.01em;
`;

export const Item = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.55)};
  display: inline-flex;
  align-items: center;
`;

export const Divider = styled.hr`
  margin: 1.5rem 0;
  border: 0;
  height: 1px;
  background-color: ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
`;

export const Invoice = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
  font-family: ${({ theme }) => theme.fonts.lexendDeca};

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const Info = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
`;

export const SecondaryInfo = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  font-size: 0.875rem;
`;

export const Tooltip = styled(DefaultTooltip).attrs((props) => ({
  arrow: true,
  placement: 'right',
  classes: { popper: props.className, tooltip: 'info__tooltip' },
}))``;

export const TooltipStyles = createGlobalStyle`
  .MuiTooltip-tooltip.info__tooltip {
    max-width: 15rem;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.gray3};
    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: -0.0033em;

    i {
      font-style: italic;
    }

    & .MuiTooltip-arrow {
      color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const InfoButton = styled.button`
  appearance: none;
  background: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  color: currentColor;
  width: 1.125rem;
  height: 1.125rem;
  display: block;
  margin-left: 0.5625rem;
`;

export const InfoLink = styled.a`
  appearance: none;
  background: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  color: currentColor;
  width: 1.125rem;
  height: 1.125rem;
  display: block;
  margin-left: 0.5625rem;
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  width: 100%;
  height: 100%;
`;

export const Cost = styled.span`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const SecondaryCost = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 1rem;
  line-height: 160%;
`;

export const BillFrequency = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  align-self: flex-end;
  font-size: 0.75rem;
`;
