import styled from 'styled-components';
import { ReactComponent as GoogleLogoIcon } from 'assets/images/icons/google.svg';
import { ContainerProps } from './types';

export const Container = styled.button<ContainerProps>`
  border: none;
  height: 2.5rem;
  padding: 0.125rem;
  box-shadow: 0 0 0.0625rem rgba(0, 0, 0, 0.084),
    0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.168);
  border-radius: 0.125rem;
  display: block;
  line-height: 2.375rem; //height - padding
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 0.875rem;
  position: relative;
  width: 100%;
  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.googleButton};
  color: ${({ theme }) => theme.colors.white};

  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

export const GoogleLogoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  float: left;
`;

export const GoogleLogo = styled(GoogleLogoIcon)``;
