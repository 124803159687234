import React, { memo } from 'react';
import { ReactComponent as Stripe } from 'assets/images/logos/stripe-logo.svg';
import { Container, Icon, Text, TextContainer } from './styles';
import { Props } from './types';

const StripeStamp = ({ className }: Props) => (
  <Container className={className}>
    <TextContainer>
      <Icon />
      <Text>Secure payments</Text>
    </TextContainer>
    <Stripe />
  </Container>
);

export default memo(StripeStamp, () => true);
