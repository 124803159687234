import { client } from 'data/client';
import { SubscriptionUsers } from '../../model/subscriptionSeats';

const API_VERSION = 'v1';

export async function getSubscriptionSeats(
  key: string,
  subscriptionId: string,
) {
  const { data } = await client.get<SubscriptionUsers>(
    `/subscriptions/${subscriptionId}/seats`,
    {
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
    },
  );
  return data;
}

export async function postSubscriptionsSeat({
  subscriptionId,
  userEmail,
}: {
  subscriptionId: string;
  userEmail: string;
}) {
  const { data } = await client.post(
    `/subscriptions/${subscriptionId}/seats`,
    {
      userEmail,
    },
    {
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
    },
  );
  return data;
}

export async function deleteSubscriptionSeat({
  subscriptionId,
  id,
}: {
  subscriptionId: string;
  id: number;
}) {
  const { data } = await client.delete(
    `/subscriptions/${subscriptionId}/seats`,
    {
      params: {
        id,
      },
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
    },
  );
  return data;
}
