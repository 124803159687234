import { getDomain } from 'data/requests/domain';
import { Domain, normalizeDomain } from 'model/domain';
import { QueryConfig, useQuery } from 'react-query';

export const useDomain = (
  { userId, includeStats = true }: { userId?: string; includeStats?: boolean },
  options?: QueryConfig<Domain>,
) => {
  const { data, status } = useQuery(
    ['domain', userId, includeStats],
    getDomain,
    {
      ...options,
      enabled: !!userId,
    },
  );
  const normalizedDomain = data && normalizeDomain(data);
  return {
    domain: normalizedDomain,
    status,
  };
};
