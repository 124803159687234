import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';
import { ReactComponent as ErrorIconSvg } from 'assets/images/icons/alert-circle.svg';
import { ReactComponent as WarningIconSvg } from 'assets/images/icons/alert-triangle.svg';

import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';

export const Content = styled.div`
  padding: 1.5rem;
  overflow: auto;
`;

export const Info = styled.div`
  display: flex;
`;

export const Error = styled.div<{
  $variant: string;
}>`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 2.25rem 1fr;
  align-items: center;
  line-height: 1.625rem;
  color: ${({ theme, $variant }) =>
    $variant === 'error' ? theme.colors.red600 : theme.colors.yellow700};
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
`;

export const Column = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.625rem;
  width: 50%;
`;

export const Label = styled.h3`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.6875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 0.8125rem;
  letter-spacing: 0.04em;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const IconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 1.375rem;
  svg {
    height: 100%;
    width: 100%;
  }
`;
export const LinkIcon = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.gray2};
  margin-left: auto;
`;
export const ErrorIcon = styled(ErrorIconSvg)`
  color: ${({ theme }) => theme.colors.red600};
  height: 1.5rem;
  width: 1.5rem;
`;
export const WarningIcon = styled(WarningIconSvg)`
  color: ${({ theme }) => theme.colors.yellow700};
  height: 1.5rem;
  width: 1.5rem;
`;

export const Link = styled.a`
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1.125rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
  &:focus-visible {
    outline-offset: -8px;
  }
`;

export const ErrorLink = styled.a.attrs({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
})`
  color: ${({ theme }) => theme.colors.blue600};
`;

export const CancelButton = styled.button<{
  $variant: 'error' | 'info';
}>`
  border: none;
  background: none;
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1.125rem 1.5rem;
  transition: background-color 0.2s ease;
  color: ${({ theme, $variant }) =>
    $variant === 'error' ? theme.colors.redDark : theme.colors.blue600};
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray1};
  }

  &:focus-visible {
    outline-offset: -8px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${IconWrapper} {
    color: ${({ theme, $variant }) =>
      $variant === 'error' ? theme.colors.redDark : theme.colors.blue600};
  }
`;

export const Placeholder = styled.span`
  height: 1.875rem;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 0.375rem;
  display: block;
`;

export const TitlePlaceholder = styled(Placeholder)`
  margin-bottom: 0.375rem;
  width: 70%;
`;

export const SubtitlePlaceholder = styled(Placeholder)`
  height: 1rem;
  width: 80%;
`;

export const SmallPlaceholder = styled(Placeholder)`
  width: 4rem;
`;
