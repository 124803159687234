import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Tooltip, TooltipStyles } from 'components/Tooltip';
import { Props } from './types';
import { StyledCopyToClipboard } from './styles';

// TODO Storybook
// TODO tests
export const CopyToClipboard: React.FC<Props> = ({ text, children }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open && copied) {
      setCopied(false);
    }
  }, [open]);

  const onClick = () => {
    copy(text);
    setCopied(true);
  };

  return (
    <>
      <TooltipStyles />
      <Tooltip
        title={copied ? 'Copied!' : 'Copy to clipboard'}
        open={open}
        disableHoverListener
        disableFocusListener
      >
        <StyledCopyToClipboard
          tabIndex={0}
          onClick={onClick}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onFocusCapture={() => setOpen(true)}
          onBlur={() => setOpen(false)}
        >
          {children}
        </StyledCopyToClipboard>
      </Tooltip>
    </>
  );
};
