import React, { FC, memo } from 'react';
import { Props } from './types';
import { Container, DownIcon, UpIcon } from './styles';

const SortByButton: FC<Props> = ({
  isSorted,
  isSortedDesc,
  isRightAligned = false,
  ...props
}) => {
  return (
    <Container $isSorted={isSorted} $isRightAligned={isRightAligned} {...props}>
      {(isSortedDesc === false || isSortedDesc === undefined) && <UpIcon />}
      {(isSortedDesc === true || isSortedDesc === undefined) && <DownIcon />}
    </Container>
  );
};

export default memo(SortByButton);
