import React, { useCallback, useMemo, useState } from 'react';
import { SelectComponentsProps } from 'react-select/src/Select';
import { Props } from './types';
import {
  PlanIdText,
  SelectContainer,
  SelectUsersText,
  StyledPlanContainer,
  StyledSelect,
  StyledText,
} from './styles';
import { PurchasedPlan } from '../../../../../../model/purchasedPlan';
import { CopyToClipboard } from '../../../../../../components/CopyToClipboard';

const getDuplicatedPlansMap = (domainPlans: PurchasedPlan[]): any[] => {
  const duplicatedPlansMap: any[] = [];

  for (let i = 0; i < domainPlans.length; i += 1) {
    if (!duplicatedPlansMap.includes(domainPlans[i].domain)) {
      for (let j = i + 1; j < domainPlans.length; j += 1) {
        if (domainPlans[i].domain === domainPlans[j].domain) {
          duplicatedPlansMap.push(domainPlans[i].domain);
          break;
        }
      }
    }
  }

  return duplicatedPlansMap;
};

function SinglePlan({
  plan,
  currentPlanUsersCount,
  ...props
}: {
  plan: PurchasedPlan;
  currentPlanUsersCount: number;
}) {
  const [domain, extension] = useMemo(() => {
    if (plan && plan.domain) {
      const [main, ...rest] = plan.domain?.split('.');
      return [main, rest.join('.')];
    }
    return ['Domain not found', ''];
  }, [plan]);

  return (
    <StyledPlanContainer>
      <StyledText {...props}>
        <strong>{domain}</strong>
        <span>.{extension}</span>
        <span>&nbsp;-&nbsp;</span>
        <strong>
          {currentPlanUsersCount}/{plan.seats}
        </strong>
        <span>&nbsp;users</span>
      </StyledText>
      <CopyToClipboard text={plan.subscriptionId}>
        <PlanIdText>
          <span>Plan ID:&nbsp;</span>
          <span>{plan.subscriptionId}</span>
        </PlanIdText>
      </CopyToClipboard>
    </StyledPlanContainer>
  );
}

function MultiplePlans({
  plans,
  onChange,
  selectedSubscription,
  currentPlanUsersCount,
  ...props
}: Props & SelectComponentsProps) {
  const duplicatedPlans = useMemo(() => getDuplicatedPlansMap(plans), [plans]);

  const options = useMemo(
    () =>
      plans.map((plan) => ({
        label: duplicatedPlans.includes(plan.domain)
          ? `${plan.domain} - ${plan.subscriptionId}`
          : plan.domain,
        value: plan.subscriptionId,
      })),
    [plans],
  );
  const [value, setValue] = useState(options[0]);
  const handleChange = useCallback(
    (item) => {
      if (onChange) {
        onChange(item);
      }
      setValue(item);
    },
    [onChange],
  );
  return (
    <StyledPlanContainer>
      <SelectContainer>
        <StyledSelect
          {...props}
          components={{
            IndicatorSeparator: () => null,
          }}
          classNamePrefix="plan-select"
          options={options}
          onChange={handleChange}
          value={value}
          menuPlacement="auto"
        />
        <SelectUsersText>
          <strong>
            {currentPlanUsersCount}/{selectedSubscription.seats}
          </strong>
          <span>&nbsp;users</span>
        </SelectUsersText>
      </SelectContainer>
      <CopyToClipboard text={selectedSubscription.subscriptionId}>
        <PlanIdText>
          <span>Plan ID:&nbsp;</span>
          <span>{selectedSubscription.subscriptionId}</span>
        </PlanIdText>
      </CopyToClipboard>
    </StyledPlanContainer>
  );
}

export function PlanSelect({
  plans,
  onChange,
  currentPlanUsersCount,
  selectedSubscription,
  ...props
}: Props & SelectComponentsProps) {
  return (
    <div>
      {plans.length === 1 && (
        <SinglePlan
          {...props}
          plan={selectedSubscription}
          currentPlanUsersCount={currentPlanUsersCount}
        />
      )}
      {plans.length > 1 && (
        <MultiplePlans
          {...props}
          plans={plans}
          selectedSubscription={selectedSubscription}
          currentPlanUsersCount={currentPlanUsersCount}
          onChange={onChange}
        />
      )}
    </div>
  );
}
