import React from 'react';
import Bold from '../../../../components/Bold';

export const SuccessMessages = {
  PERSONAL: 'You can now send emails up to 400 recipients a day.',
  PROFESSIONAL: 'You can now send emails up to 1,500 recipients a day.',
  TEAM: (
    <>
      <span>You can now add your collaborators to your team in the&nbsp;</span>
      <Bold>Manage your users</Bold>
      <span>&nbsp;section down below</span>
      <br />
      <span>
        Your domain’s users can now send emails up to 1,500 recipients a day.
      </span>
    </>
  ),
};
