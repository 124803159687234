import React, { FC, memo } from 'react';

import { ReactComponent as MailMerge } from 'assets/images/icons/mail-merge-tutorial.svg';
import { ReactComponent as Unsubscription } from 'assets/images/icons/unsubscription.svg';
// import { ReactComponent as Template } from 'assets/images/icons/template.svg';
import { ReactComponent as Team } from 'assets/images/icons/team.svg';
import { ReactComponent as Tutorial } from 'assets/images/icons/mail-merge.svg';
import { AnimatePresence } from 'framer-motion';
import testingVar from 'utils/testingVar';
import { clickTutorial } from 'containers/Guest/SignUp/events';
import {
  Container,
  Header,
  Title,
  Logo,
  Navigation,
  Item,
  ItemIcon,
  Overlay,
  TopSection,
  BottomSection,
} from './styles';
import { Props } from './types';
import SmallCannonNavBanner from '../SmallCannonNavBanner';

const MobileMenu: FC<Props> = ({
  canAccessTeamReport,
  open,
  closeMenu,
  cannon,
  showTutorial,
  ...props
}) => {
  const handleClick = () => closeMenu();
  const handleTutorial = () => {
    clickTutorial();
    closeMenu();
  };

  return (
    <>
      <AnimatePresence>
        {open && (
          <Overlay
            data-test-id={testingVar('submit-close')}
            key="menu-overlay"
            onClick={closeMenu}
          />
        )}
      </AnimatePresence>
      <Container data-test-id={testingVar('menu')} {...props} $open={open}>
        <TopSection>
          <Header>
            <Logo />
            <Title>Yamm</Title>
          </Header>
          <Navigation>
            <Item to="/mail-merges" onClick={handleClick}>
              <ItemIcon as={MailMerge} />
              Mail merges
            </Item>
            <Item to="/unsubscriptions" onClick={handleClick}>
              <ItemIcon as={Unsubscription} />
              Unsubscriptions
            </Item>
            {
            /*
            <Item to="/templates" onClick={handleClick}>
              <ItemIcon as={Template} />
              Templates
            </Item>
            */
            }
            {canAccessTeamReport && (
              <Item to="/team-report" onClick={handleClick}>
                <ItemIcon as={Team} />
                Team report
              </Item>
            )}
            {showTutorial && (
              <Item to="/get-started" onClick={handleTutorial}>
                <ItemIcon as={Tutorial} />
                Install YAMM
              </Item>
            )}
          </Navigation>
        </TopSection>
        <BottomSection>
          {cannon && cannon.enabled && <SmallCannonNavBanner cannon={cannon} />}
        </BottomSection>
      </Container>
    </>
  );
};

export default memo(MobileMenu);
