import styled from 'styled-components';
import { from } from 'styles/media';

import { ReactComponent as YammLogo } from 'assets/images/brand/YAMM-Logotype.svg';

export const Container = styled.div``;

export const ImageWrapper = styled.div`
  height: 1.25rem;
`;

export const YammImage = styled(YammLogo)`
  height: 5rem;
  width: 5rem;
  transform: translateY(-100%);
`;

export const Main = styled.main`
  grid-area: main;
  align-self: center;
`;

export const Nav = styled.nav``;

export const NavLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  display: inline-block;
  letter-spacing: -0.0033em;
  margin: 0.5rem 0.625rem;
  position: relative;

  ${from.mobile} {
    :not(:last-child) {
      &:after {
        content: '·';
        position: absolute;
        right: -0.75rem;
      }
    }
  }
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;
  margin: 1.375rem auto;
  max-width: 30rem;
  padding: 1.25rem 0.625rem;
  text-align: center;
  box-shadow: 0 1.25rem 1.25rem -1.25rem rgba(0, 0, 0, 0.1);

  ${from.mobile} {
    margin: 1.25rem auto;
    padding: 2.5rem 5rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.lexendDeca};

  ${from.tablet} {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`;

export const AgreeToSText = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.suvaGrey};
  font-size: 0.75rem;
  line-height: 160%;
  margin-top: 1rem;
  letter-spacing: -0.0066em;
  display: block;
`;

export const TosLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  color: ${({ theme }) => theme.colors.orange600};
`;

export const LinkWrapper = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  margin-top: 3.125rem;

  img {
    width: 7.5rem;
  }
`;
