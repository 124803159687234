import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  box-shadow: 0px -1px 0px #537183;
`;

export const SmallCannonEntry = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    color: #fb7858;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const SmallCannonTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
`;

export const SmallCannonSubTitle = styled(SmallCannonTitle)`
  opacity: 0.75;
  margin-bottom: 13px;
`;

export const SmallCannonLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.75;
  margin-left: 10px;
  text-decoration: none;
`;
