import React, { FC } from 'react';
import { Props } from './types';
import { Container, ForegroundContainer, Title } from './styles';

const Empty: FC<Props> = ({ background, foreground, ...props }) => {
  return (
    <Container {...props}>
      {background}
      <ForegroundContainer>
        {foreground || <Title>No results found</Title>}
      </ForegroundContainer>
    </Container>
  );
};

export default Empty;
