import { client } from 'data/client';
import { Input } from 'model/unsubscription';

type GetUnsubscriptionsResponse = {
  items: Input[];
};

export async function getUnsubscriptions(key: string, userId?: string) {
  const { data } = await client.get<GetUnsubscriptionsResponse>(
    `/users/${userId}/unsubscriptions`,
    {
      params: {
        limit: 30000,
      },
    },
  );
  return data.items;
}

export type PostUnsubscriptionsDto = {
  userId: string;
  email: string;
};

export async function postUnsubscriptions({
  userId,
  ...body
}: PostUnsubscriptionsDto) {
  const { data } = await client.post(`/users/${userId}/unsubscriptions`, {
    ...body,
  });
  return data;
}

export type DeleteUnsubscriptionsDto = {
  userId: string;
  email: string;
};

export async function deleteUnsubscriptions({
  userId,
  email,
}: DeleteUnsubscriptionsDto) {
  const { data } = await client({
    method: 'DELETE',
    url: `/users/${userId}/unsubscriptions`,
    data: { email },
  });
  return data;
}
