import { client } from 'data/client';
import { Input } from 'model/billingInfo';

const API_VERSION = 'v2';

type GetBillingInfoResponse = Input;

export async function getBillingInfo(key: string, userId: string) {
  const { data } = await client.get<GetBillingInfoResponse>(
    `/users/${userId}/billingInfo`,
    {
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
    },
  );
  return data;
}
