type Input = string | null | undefined;

export type Plan = 'FREE' | 'PAID' | 'DOMAIN_PAID';

export const normalizePlan = (input: Input) => {
  const plan = input?.toUpperCase();

  switch (plan) {
    case 'FREE':
      return 'FREE';
    case 'PERSONAL':
      return 'PERSONAL';
    case 'PROFESSIONAL':
      return 'PROFESSIONAL';
    case 'TEAM':
      return 'TEAM';
    case 'UNLIMITED':
      return 'UNLIMITED';
    default:
      return undefined;
  }
};

export type NewPlan = NonNullable<ReturnType<typeof normalizePlan>>;
