import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { Props } from './types';

export const Container = styled.div<{
  $variant: Props['variant'];
}>`
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.red};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.375rem;

  ${({ $variant }) => {
    switch ($variant) {
      case 'green':
      default:
        return css`
          background-color: ${({ theme }) => addAlpha(theme.colors.green, 0.2)};
          color: ${({ theme }) => theme.colors.green};
        `;

      case 'red':
        return css`
          background-color: ${({ theme }) => addAlpha(theme.colors.red, 0.2)};
          color: ${({ theme }) => theme.colors.red};
        `;

      case 'purple':
        return css`
          background-color: ${({ theme }) =>
            addAlpha(theme.colors.purple, 0.2)};
          color: ${({ theme }) => theme.colors.purple};
        `;

      case 'orange':
        return css`
          background-color: ${({ theme }) =>
            addAlpha(theme.colors.orange, 0.2)};
          color: ${({ theme }) => theme.colors.orange};
        `;
    }
  }}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
