import React, { FC } from 'react';
import { useMediaQuery, from } from 'styles/media';
import testingVar from 'utils/testingVar';

import {
  BaseModal,
  ModalHeader,
  Title,
  CloseButton,
  CloseIcon,
  Subtitle,
} from './styles';
import { Props } from './types';

const Modal: FC<Props> = ({
  title,
  subtitle,
  children,
  onClose,
  open,
  ...rest
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  return (
    <BaseModal
      fullScreen={isMobile}
      onClose={onClose}
      open={open}
      animation={isMobile ? 'slideUp' : 'fade'}
      {...rest}
    >
      {title && (
        <ModalHeader>
          <Title>{title}</Title>
          <CloseButton
            data-test-id={testingVar('submit-close')}
            onClick={onClose}
          >
            <CloseIcon />
          </CloseButton>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </ModalHeader>
      )}
      {children}
    </BaseModal>
  );
};

export default Modal;
