import React, { FC, memo, useCallback } from 'react';
import Loader from 'components/Loader';
import { from, useMediaQuery } from 'styles/media';

import { Props } from './types';
import {
  TemplateCardContainer,
  Thumb,
  Image,
  ImagePlaceholder,
  Header,
  Title,
  TitlePlaceholder,
  Button,
  ButtonPlaceholder,
} from './styles';

const TemplateCard: FC<Props> = ({
  messageId,
  loading,
  disabled,
  image,
  title,
  url,
  onImport,
  id,
  loggedUserIndex,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  const handleImport = useCallback(() => {
    if (!onImport || !id) return;
    onImport({ fromTemplate: id });
  }, [id, onImport]);

  return (
    <TemplateCardContainer {...props}>
      <Thumb to={url || ''}>
        {image !== undefined ? <Image src={image} /> : <ImagePlaceholder />}
      </Thumb>
      <Header>
        {title !== undefined ? <Title>{title}</Title> : <TitlePlaceholder />}
        {!isMobile && (
          <>
            {url !== undefined ? (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {messageId ? (
                      <Button
                        forwardedAs="a"
                        href={`https://mail.google.com/mail/u/${loggedUserIndex}/#drafts?compose=${messageId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        onClick={handleImport}
                        disabled={disabled}
                      >
                        Use
                      </Button>
                    )}
                  </>
                )}
              </>
            ) : (
              <ButtonPlaceholder />
            )}
          </>
        )}
      </Header>
    </TemplateCardContainer>
  );
};

export default memo(TemplateCard);
