import React, { FC, memo } from 'react';
import testingVar from 'utils/testingVar';
import { Props } from './types';
import { LoaderContainer, Progress } from './styles';

const Loader: FC<Props> = ({ size = 16, thickness, ...props }) => {
  return (
    <LoaderContainer data-test-id={testingVar('loader')} {...props}>
      <Progress size={size} thickness={thickness} />
    </LoaderContainer>
  );
};

export default memo(Loader);
