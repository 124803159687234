import styled from 'styled-components';
import { visuallyHidden } from 'styles/mixins';
import addAlpha from 'utils/addAlpha';

export const Container = styled.div`
  width: 1.125rem;
  height: 1.125rem;
`;

export const Input = styled.input`
  ${visuallyHidden};

  &:focus + label {
    box-shadow: 0 0 0 2px
      ${({ theme }) => addAlpha(theme.colors.orange600, 0.25)};
    outline-offset: 0.25rem;
  }

  &:checked + label {
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
    &::after {
      opacity: 1;
      transform: none;
    }
  }
`;

export const Radio = styled.label`
  appearance: radio;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  transition: box-shadow 200ms ease;
  border: 0.1rem solid ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};

  &:hover {
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  }

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
    background-color: ${({ theme }) => theme.colors.orange600};
    transition: all 200ms ease;
    transform: scale(0);
    opacity: 0;
  }
`;
