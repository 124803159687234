import React from 'react';
import { useSnackbar as useDefaultSnackbar, OptionsObject } from 'notistack';
import { Props } from './types';
import Snackbar from '.';

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar();

  const pushSnackbar = (
    message: string,
    options?: OptionsObject & Partial<Omit<Props, 'id' | 'index'>>,
  ) => {
    enqueueSnackbar(message, {
      ...options,
      content: (key) => {
        const { variant, onUndo, undoId } = options || {
          variant: undefined,
          onUndo: undefined,
          undoId: undefined,
        };
        return (
          <Snackbar
            id={key}
            index={0}
            message={message}
            variant={variant}
            undoId={undoId}
            onUndo={onUndo}
          />
        );
      },
    });
  };

  return pushSnackbar;
};
