import { useQuery } from 'react-query';
import { getScheduledMailMerges } from 'data/requests/scheduledMailMerges';
import { normalizeScheduledCampaign } from 'model/scheduledCampaign';

export const useScheduledMailMerges = (userId?: string) => {
  const { data, status } = useQuery(
    ['scheduled-mail-merges', userId],
    getScheduledMailMerges,
    {
      enabled: !!userId,
    },
  );
  const scheduledMailMerges =
    data &&
    data
      .map(normalizeScheduledCampaign)
      .filter((mail) => mail.status !== 'COMPLETED');
  return { scheduledMailMerges, status };
};
