import styled, { createGlobalStyle } from 'styled-components';
import { from } from 'styles/media';
import ProgressBar from 'components/ProgressBar';
import { ReactComponent as ExternalIcon } from 'assets/images/icons/external-link.svg';
import { ReactComponent as SentIcon } from 'assets/images/icons/send.svg';
import { Link } from 'react-router-dom';
import addAlpha from 'utils/addAlpha';
import { TextEllipsis } from 'styles/mixins';
import DefaultTabs from 'components/Tabs';
import { Tooltip as DefaultTooltip } from '@material-ui/core';
import { ReactComponent as DefaultInfoIcon } from '../../../assets/images/icons/info.svg';
import { Text } from '../../../components/StatsCard/styles';

export const Container = styled.div`
  --maxWidth: 58.5rem;
  margin: 0 auto;
  padding: 2.1875rem 0;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  color: ${({ theme }) => theme.colors.gray4};

  > * {
    grid-column: 2;
  }

  ${from.tablet} {
    grid-template-columns: auto var(--maxWidth) auto;
  }

  ${from.laptop} {
    padding-top: 2.1875rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: -6px;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
`;

export const NewButton = styled.a`
  margin-left: auto;
  border-radius: 0.25rem;
  border: 0.0625rem solid transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.375rem;
  text-decoration: none;
  transition: all 0.2s ease;
  background: none;
  border-color: ${({ theme }) => theme.colors.orange600};
  color: ${({ theme }) => theme.colors.orange600};
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  line-height: 1.5rem;

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.orange500};
    color: ${({ theme }) => theme.colors.white};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.orange800};
    color: ${({ theme }) => theme.colors.white};
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }
`;

export const Sent = styled(SentIcon)`
  margin-right: 0.75rem;
`;

export const StatsContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  grid-column: 1 / -1;
  padding-left: 1rem;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    flex: 1 0 1rem;
  }

  ${from.tablet} {
    grid-column: 2;
    &::after {
      content: none;
    }
    padding-left: 0;
  }

  > * {
    :not(:first-child) {
      margin-left: 1.5rem;
    }
    flex: 1 0 18.5rem;
    scroll-snap-align: start;
    scroll-margin-inline-start: 1rem;
    ${from.tablet} {
      flex: 1;
    }
  }
`;

export const Tabs = styled(DefaultTabs)`
  grid-column: 1 / -1;

  ${from.tablet} {
    grid-column: 2;
  }
`;

export const Progress = styled(ProgressBar)`
  width: 2.5rem;
  margin-right: 0.75rem;
`;

export const CampaignLink = styled(Link)`
  text-decoration: none;
  line-height: 1rem;
  ${TextEllipsis};
`;

export const SheetLink = styled.a`
  display: inline-flex;
`;

export const SheetIcon = styled(ExternalIcon)`
  --size: 1rem;
  width: var(--size);
  height: var(--size);
  color: ${({ theme }) => theme.colors.gray4};
`;

export const EmailsSentText = styled(Text)`
  display: inline-flex;
  align-items: center;
`;

export const Tooltip = styled(DefaultTooltip).attrs((props) => ({
  arrow: true,
  placement: 'bottom',
  classes: { popper: props.className, tooltip: 'info__tooltip' },
}))``;

export const TooltipStyles = createGlobalStyle`
  .MuiTooltip-tooltip.info__tooltip {
    max-width: 15rem;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.gray3};
    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: -0.0033em;

    i {
      font-style: italic;
    }

    & .MuiTooltip-arrow {
      color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.4rem;
`;
