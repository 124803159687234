import React, { FC, memo, useCallback } from 'react';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { formatShortMonthDayYear } from 'utils/dates';
import { Props } from './types';
import { RowContainer, Column, InfoItem, Info, Name } from '../styles';
import { Button, Icon } from './styles';

const MailRow: FC<Props> = ({ id, mail, date, onDelete, ...props }) => {
  const handleDelete = useCallback(() => {
    if (!onDelete) return;
    onDelete(id);
  }, [id, onDelete]);

  return (
    <RowContainer {...props}>
      <Column>
        <Name>{mail}</Name>
        <Info>
          <InfoItem>
            {date ? (
              <DateTimeCell
                value={date}
                formatFunction={formatShortMonthDayYear}
              />
            ) : null}
          </InfoItem>
        </Info>
      </Column>
      <Column>
        <Button onClick={handleDelete}>
          <Icon />
        </Button>
      </Column>
    </RowContainer>
  );
};

export default memo(MailRow);
