import { client } from 'data/client';
import { Input } from 'model/paymentInfo';

type GetPaymentInfoResponse = Input;

export type BusinessType = 'B2B' | 'B2C';

export interface PaymentInfoDto {
  type: BusinessType;
  country: string;
}

export async function getPaymentInfo(key: string, params: PaymentInfoDto) {
  const { data } = await client.get<GetPaymentInfoResponse>(
    `/payment/paymentInfo`,
    {
      params,
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_PAYMENT_ENDPOINT_URL_BASE}`,
    },
  );
  return data;
}
