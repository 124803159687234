import { sha256 } from 'js-sha256';
import { User } from '../../model/user';
import { getUserIdFromEmail } from '../userUtils';
import { Promotion } from '../../model/promotion';

const YAMM_TRACKING_SESSION_ID_KEY = 'yamm_tracking_session_id';

export const SMALL_CANNON_EVENT_TYPE = {
  AD_CLICK: 'ad_clicked',
  AD_DISPLAY: 'ad_displayed',
  AD_LIST_DISPLAY: 'ad_list_displayed',
};

const getTrackingSessionId = (userEmail?: string): string => {
  let trackingSessionId: string | null = sessionStorage.getItem(
    YAMM_TRACKING_SESSION_ID_KEY,
  );
  if (trackingSessionId == null) {
    if (userEmail != null) {
      trackingSessionId = `${getUserIdFromEmail(userEmail)}-${Date.now()}`;
    } else {
      trackingSessionId = 'missing_tracking_session_id';
    }
    sessionStorage.setItem(YAMM_TRACKING_SESSION_ID_KEY, trackingSessionId);
  }
  return trackingSessionId;
};

export const generateLogObject = (
  eventName: string,
  data?: Promotion & any,
  user?: User | null,
) => {
  let logData: any = {
    timestamp: Date.now(),
    event_name: eventName,
    event_type: 'small_cannon',
    user_agent: navigator.userAgent,
    app: 'webapp',
    placement: 'sidebar',
    ad_unit: 'text',
    yamm_tracking_session_id: sha256(getTrackingSessionId(user?.email)),
  };

  if (data) {
    logData = Object.assign(logData, {
      destination_url: data.url,
      destination_type: data.type,
      ad_name: data.name,
      product: data.productId,
      products_link_text: data.listHead,
    });
  }

  if (user) {
    logData = Object.assign(logData, {
      user_email: sha256(user.email),
      user_id: sha256(getUserIdFromEmail(user.email)),
      paid_status: user.plan,
    });
  }

  return logData;
};

export const appendUtmString = (url: string, placement: string): string => {
  return `${url}?utm_source=yamm&utm_medium=cannon&utm_campaign=webapp_${placement}`;
};
