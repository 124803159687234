import React, { FC, memo, useCallback } from 'react';
import { ReactComponent as BillingIcon } from 'assets/images/icons/clipboard.svg';
import { ReactComponent as ShuffleIcon } from 'assets/images/icons/shuffle.svg';
import { ReactComponent as ExitIcon } from 'assets/images/icons/logout.svg';
import testingVar from 'utils/testingVar';

import { Props } from './types';
import Avatar from '../Avatar';
import {
  Container,
  Trigger,
  Dropdown,
  Item,
  ItemIcon,
  ItemText,
  Chevron,
  Account,
  Photo,
  AccountInfo,
  Name,
  Email,
  Link,
} from './styles';
import { useLogic } from './logic';

const AccountDropdown: FC<Props> = ({
  name,
  email,
  photo,
  initials,
  onAccountSwitch,
  onLogout,
  ...props
}) => {
  const { isOpen, anchor, handleOpen, handleClose } = useLogic();

  const onSwitch = useCallback(() => {
    onAccountSwitch();
    handleClose();
  }, [onAccountSwitch, handleClose]);

  return (
    <Container {...props}>
      <Trigger
        aria-controls="account-menu"
        aria-haspopup="true"
        data-testid="trigger"
        data-test-id={testingVar('account-dropdown')}
        onClick={handleOpen}
      >
        <Avatar src={photo} initials={initials} />
      </Trigger>
      <Dropdown
        id="account-menu"
        anchorEl={anchor}
        keepMounted
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
      >
        <Account>
          <Photo src={photo} initials={initials} />
          <AccountInfo>
            <Name>{name}</Name>
            <Email data-test-id={testingVar('user-email')}>{email}</Email>
          </AccountInfo>
        </Account>
        <Link
          to="/settings/billing"
          onClick={handleClose}
          data-testid="billing"
        >
          <Item data-test-id={testingVar('view-billing')}>
            <ItemIcon>
              <BillingIcon />
            </ItemIcon>
            <ItemText>Manage billing</ItemText>
            <Chevron />
          </Item>
        </Link>
        <Item
          data-test-id={testingVar('submit-switch')}
          onClick={onSwitch}
          data-testid="switch"
        >
          <ItemIcon>
            <ShuffleIcon />
          </ItemIcon>
          <ItemText>Switch account</ItemText>
          <Chevron />
        </Item>
        <Item
          data-test-id={testingVar('submit-logout')}
          onClick={onLogout}
          data-testid="logout"
        >
          <ItemIcon>
            <ExitIcon />
          </ItemIcon>
          <ItemText>Log out</ItemText>
          <Chevron />
        </Item>
      </Dropdown>
    </Container>
  );
};

export default memo(AccountDropdown);
