import React, { useMemo } from 'react';
import { safeDateFormat } from 'utils/safeDateFormat';
import { safeTimeElementProps } from 'utils/safeTimeElementProps';

const DateTimeCell = ({
  value,
  formatFunction,
}: {
  value: number | string | Date;
  formatFunction: (date: number | Date) => string;
}) => {
  const date = useMemo(() => new Date(value), [value]);
  const formattedDate = useMemo(() => safeDateFormat(formatFunction, date), [
    date,
    formatFunction,
  ]);
  const timeElementProps = useMemo(() => safeTimeElementProps(date), [date]);
  return <time {...timeElementProps}>{formattedDate}</time>;
};

export default DateTimeCell;
