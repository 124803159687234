import { queryCache } from 'data/cache';
import {
  deleteUnsubscriptions,
  getUnsubscriptions,
  postUnsubscriptions,
} from 'data/requests/unsubscriptions';
import { normalizeUnsubscription } from 'model/unsubscription';
import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';

export const useUnsubscriptions = ({ userId }: { userId?: string }) => {
  const { data, status } = useQuery(
    ['unsubscriptions', userId],
    getUnsubscriptions,
    {
      enabled: !!userId,
    },
  );
  const unsubscriptions = (data || []).map(normalizeUnsubscription);
  return { unsubscriptions, status };
};

export const useUnsubscribe = ({ userId }: { userId?: string }) => {
  const [mutate] = useMutation(postUnsubscriptions);

  const unsubscribe = useCallback(
    async (emails: string[]) => {
      if (!userId) return;
      const promises = emails.map((email) => {
        return mutate({ userId, email }, { throwOnError: true });
      });
      // eslint-disable-next-line consistent-return
      return Promise.all(promises).then(() => {
        queryCache.invalidateQueries('unsubscriptions');
      });
    },
    [mutate, userId],
  );

  return unsubscribe;
};

export const useDeleteUnsubscribe = ({ userId }: { userId?: string }) => {
  const [mutate] = useMutation(deleteUnsubscriptions);

  const unsubscribe = useCallback(
    async (email: string) => {
      if (!userId) return;
      // eslint-disable-next-line consistent-return
      return mutate(
        { userId, email },
        {
          throwOnError: true,
          onSuccess: () => {
            queryCache.invalidateQueries('unsubscriptions');
          },
        },
      );
    },
    [mutate, userId],
  );

  return unsubscribe;
};
