import { AxiosError } from 'axios';
import { useEnqueueSnackbar } from 'components/Snackbar/useEnqueueSnackbar';
import { useMe } from 'data/hooks/useMe';
import {
  useDeleteUnsubscribe,
  useUnsubscribe,
  useUnsubscriptions,
} from 'data/hooks/useUnsubscriptions';
import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { captureException } from '@sentry/minimal';
import { getColumns } from './table/columns';
import { DuplicatedUnsubscriptionError, UnsubscriptionForm } from './types';
import { useStackDriverLogging } from '../../../data/context/StackDriverLogging';
import { getUserDataForLogging } from '../../../utils/logging';
import { isValidEmail } from '../../../utils/userUtils';

export const useConnect = () => {
  const { me } = useMe();
  const { unsubscriptions, status } = useUnsubscriptions({ userId: me?.email });
  const unsubscribe = useUnsubscribe({ userId: me?.email });
  const deleteUnsubscribe = useDeleteUnsubscribe({ userId: me?.email });
  const enqueue = useEnqueueSnackbar();
  const { logToStackDriver, getUser } = useStackDriverLogging();

  const validate = useCallback((values: Partial<UnsubscriptionForm>) => {
    const errors: Partial<UnsubscriptionForm> = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !values.email.split(/[,\s]/).filter(Boolean).every(isValidEmail)
    ) {
      errors.email = 'An email is invalid';
    }

    return errors;
  }, []);

  const onSubmit = useCallback(
    (
      values: UnsubscriptionForm,
      { setSubmitting, resetForm, validateForm }: FormikHelpers<any>,
    ) => {
      let commit = true;
      const emails = values.email.split(/[,\s]/).filter(Boolean);
      const performUnsubscribe = async () => {
        try {
          if (commit) {
            await unsubscribe(emails);
            resetForm();
            validateForm();
            enqueue(
              `Email address${emails.length > 1 ? 'es' : ''} unsubscribed`,
            );
          }
        } catch (error) {
          captureException(error);
          const { message } = ((error as AxiosError).response
            ?.data as DuplicatedUnsubscriptionError).error;
          enqueue(message || 'An error occurred. Please try again.', {
            variant: 'warning',
          });
        } finally {
          setSubmitting(false);
        }

        // log to the stack driver unsubscribed emails
        try {
          const user = getUserDataForLogging(getUser());
          logToStackDriver({
            emails_count: emails.length,
            timestamp: Date.now(),
            user_id: user.user_id,
            user_installation_timestamp: user.user_installation_timestamp,
            plan: user.plan,
            is_biz_user: user.is_biz_user,
            domain: user.domain,
            event_type: 'manual_unsubscribe',
            message: 'User manually unsubscribed emails (manual_unsubscribe)',
          });
        } catch (e) {
          captureException(e);
        }
      };

      enqueue(`Unsubscribing...`, {
        autoHideDuration: 2000,
        onExited: performUnsubscribe,
        undoId: `unsubscribe-${emails.join(',')}`,
        onUndo: () => {
          commit = false;
          setSubmitting(false);
        },
      });
    },
    [unsubscribe, enqueue],
  );

  const initialValues = { email: '' };

  const onDelete = useCallback(
    (unsubscriptionId: string) => {
      let commit = true;
      const performDeleteUnsubscription = async () => {
        try {
          if (commit) {
            await deleteUnsubscribe(unsubscriptionId);
            enqueue(`Deleted unsubscription`);
          }
        } catch {
          enqueue('An error ocurred. Please try again.', { variant: 'error' });
        }
      };

      enqueue(`Deleting unsubscription...`, {
        autoHideDuration: 2000,
        onExited: performDeleteUnsubscription,
        undoId: `delete-unsubscribe-${unsubscriptionId}`,
        onUndo: () => {
          commit = false;
        },
      });
    },
    [deleteUnsubscribe, enqueue],
  );

  const columns = useMemo(() => getColumns({ onDelete }), [onDelete]);

  return {
    me,
    unsubscriptions,
    status,
    columns,
    initialValues,
    handle: {
      validate,
      delete: onDelete,
      submit: onSubmit,
    },
  };
};
